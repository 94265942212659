/**
 * Get key by value in object
 *
 * @param {*} object
 * @param {*} value
 * @return {*}
 */
const getKeyByValue = (object, value) => Object.keys(object).find(key => object[key] === value);

export default getKeyByValue;
