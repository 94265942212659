import { createAction, createSlice } from '@reduxjs/toolkit';

/**
 * @type {{errors: *[]}}
 */
export const initialState = {
  errors: [],
  messages: [],
};

export const resetApp = createAction('root/reset');

/**
 * @type {Slice<{data: null, error: null, status: string}, {}, string>}
 */
export const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    addGlobalError: (state, action) => {
      state.errors.push(action.payload);
    },
    removeGlobalError: (state, action) => {
      state.errors = state.errors.filter((_, index) => index !== action.payload);
    },
    clearGlobalErrors: state => {
      state.errors = initialState.errors;
    },
    addGlobalMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    removeGlobalMessage: (state, action) => {
      state.messages = state.messages.filter((_, index) => index !== action.payload);
    },
    clearGlobalMessages: state => {
      state.messages = initialState.messages;
    },
  },
});

export const {
  addGlobalError, removeGlobalError, clearGlobalErrors,
  addGlobalMessage, removeGlobalMessage, clearGlobalMessages,
} = rootSlice.actions;

export const selectErrors = state => state.root.errors;
export const selectMessages = state => state.root.messages;

export default rootSlice.reducer;
