import { ADDRESS_TYPES } from '../enums';

/**
 * Get shipping address from addresses array
 *
 * @param {*} addresses
 * @return {*}
 */
const getShippingAddress = addresses => {
  const [ address ] = addresses.filter(value => value.type === ADDRESS_TYPES.SHIPPING);
  return address || null;
};

export default getShippingAddress;
