import React from 'react';
import loadable from '@loadable/component';
import { useSelector } from 'react-redux';
import { AuthValidationWrapper } from '../components';
import { fetchShipments } from '../features/shipment/shipmentSlice';
import { selectAuthData } from '../features/auth/authSlice';

const HistoryPageContent = loadable(() => import(/* webpackPrefetch: true */ '../components/HistoryPageContent'));

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const History = props => {
  const auth = useSelector(selectAuthData);

  return (
    <AuthValidationWrapper auth={auth}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <HistoryPageContent {...props} />
    </AuthValidationWrapper>
  );
};

const loadData = store => store.dispatch(fetchShipments());

export default {
  component: History,
  loadData,
};
