import React from 'react';
import loadable from '@loadable/component';
import { useSelector } from 'react-redux';
import { AuthValidationWrapper } from '../components';
import { fetchAccount } from '../features/account/accountSlice';
import { selectAuthData } from '../features/auth/authSlice';
import { getTrue } from '../../shared/utils';
import LogoutDemo from '../components/LogoutDemo';

const AccountPageContent = loadable(() => import(/* webpackPrefetch: true */ '../components/AccountPageContent'));

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Account = props => {
  const auth = useSelector(selectAuthData);

  return (
    <AuthValidationWrapper auth={auth}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <AccountPageContent {...props} />

      {(auth && getTrue(IS_DEMO)) && (
        <LogoutDemo
          auth={auth}
        />
      )}
    </AuthValidationWrapper>
  );
};

const loadData = store => store.dispatch(fetchAccount());

export default {
  component: Account,
  loadData,
};
