import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconAccount from '../../assets/images/rookieparents.svg';
import { selectAuthData, signOut } from '../features/auth/authSlice';

const LogoutDemo = () => {
  const dispatch = useDispatch();
  const auth = useSelector(selectAuthData);

  const onSignOutClick = e => {
    e.preventDefault();
    const { refresh_token: token } = auth;
    dispatch(signOut({ token }));
  };

  const name = auth ? `${auth.firstName ?? ''} ${auth.lastName ?? ''}` : '';

  return (
    <div
      className='flex w-[100%] items-center xl:hidden py-[23px] pl-[34px] px-0 text-left bottom-[34px] left-[34px] border-t-2 border-hr-gray'
      style={{ marginTop: 'auto' }}
    >
      <div>
        <img src={IconAccount} alt='Account icon' />
      </div>
      <div className='ml-[13px]'>
        <div className='text-lg font-primary-family-medium'>{name}</div>
        <div className='header-logout__link'>
          <button type='button' className='text-red font-primary-family-medium' onClick={onSignOutClick}>
            logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default LogoutDemo;
