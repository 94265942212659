import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axios } from '../../../shared/clients';
import { STATUS } from '../../../shared/enums';
import { setPlans } from '../plans/plansSlice';
import { addGlobalError } from '../root/rootSlice';

/**
 * @type {{data: null, error: null, status: string}}
 */
export const initialState = {
  data: null,
  status: STATUS.IDLE,
  error: null,
};

export const cancelRefill = createAsyncThunk('cancelRefill/cancelRefill', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post('/refill/cancel', payload);
    await dispatch(setPlans(response?.data));
    return response?.data;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

/**
 * @type {Slice<{data: null, error: null, status: string}, {}, string>}
 */
export const cancelRefillSlice = createSlice({
  name: 'cancelRefill',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(cancelRefill.pending, state => {
        state.status = STATUS.LOADING;
      })
      .addCase(cancelRefill.fulfilled, (state, action) => {
        state.status = STATUS.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(cancelRefill.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload;
      });
  },
});

export const selectCancelRefillData = state => state.cancelRefill.data;
export const selectCancelRefillStatus = state => state.cancelRefill.status;
export const selectCancelRefillError = state => state.cancelRefill.error;

export default cancelRefillSlice.reducer;
