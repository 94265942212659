/**
 *  convertEmptyStringToNull in object
 *
 * @param {*} obj
 * @return {*}
 */
function convertEmptyStringToNull(obj) {
  const newObj = {};
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object') {
      newObj[key] = convertEmptyStringToNull(obj[key]);
    } else if (obj[key] === '') {
      newObj[key] = null;
    } else {
      newObj[key] = obj[key];
    }
  });
  return newObj;
}

export default convertEmptyStringToNull;
