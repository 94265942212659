import React from 'react';

/**
 * Represents the top bar component.
 * @component
 */
const TopBar = () => (
  <div className='top-bar'>
    <div className='top-bar__container'>
      <p className='top-bar__info'>
        My Account is currently undergoing upgrades. 🛠️ Having trouble? Please contact
        {' '}
        <a href='mailto:support@milacares.com' style={{ textDecoration: 'underline' }}>the Care Squad</a>
      </p>
    </div>
  </div>
);

export default TopBar;
