import React from 'react';
import { FILTERS_INFO } from '../../shared/constants';
import HistoryItemStatus from './History/HistoryItemStatus';

const DashboardHistoryPlan = ({ plan }) => (
  <div className='upcoming-plan'>
    <div className='upcoming-plan-header'>
      <HistoryItemStatus fulfillment={plan.fulfillment} service={plan.service} />
    </div>
    {!!plan.details?.length && plan.details.map(detail => (
      <div className='upcoming-plan-variant'>
        <div className='upcoming-plan-variant-count'>
          {detail.count}
          x
        </div>
        <div className='upcoming-plan-variant-name'>
          {FILTERS_INFO[detail.variant].title}
        </div>
      </div>
    ))}
  </div>
);

export default DashboardHistoryPlan;
