import { SUPPORTED_REGIONS } from '../enums';
import getShippingAddress from './getShippingAddress';

/**
 * Get region from auto-refill plan
 *
 * @param {*} plan
 * @return {*}
 */
const getPlanRegion = plan => {
  let output = null;

  const address = getShippingAddress(plan.addresses);

  if (address?.country) {
    const isSupportedEURegion = Object.keys(SUPPORTED_REGIONS.EU).includes(address.country);
    output = isSupportedEURegion ? 'EU' : address.country;
  } else {
    output = plan.cart_region;
  }

  return output;
};

export default getPlanRegion;
