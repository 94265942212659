import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { useLocation } from 'react-router-dom';

import {
  CommonHelmetWrapper,
  ErrorBoundary,
  Icons,
  Sidebar,
  TopBar,
} from './components';

import '../assets/css/style.scss';
import { selectAuthData, signOut, tokenExchange } from './features/auth/authSlice';
import { clearGlobalErrors, clearGlobalMessages, selectErrors } from './features/root/rootSlice';
import HeaderDemo from './components/HeaderDemo';
import { NAV_ROUTES, PAGES_WITHOUT_NAV } from '../shared/constants';
import Loader from './components/Loader';
import ErrorPopup from './components/ErrorPopup';
import MessagePopup from './components/MessagePopup';

const App = ({ route, location: { pathname }}) => {
  const dispatch = useDispatch();
  const auth = useSelector(selectAuthData);
  const errors = useSelector(selectErrors);
  const location = useLocation();

  useEffect(() => {
    errors.forEach(error => {
      if (error?.status === 401 && auth) {
        const { refresh_token: token } = auth;
        dispatch(signOut({ token }));
      }
    });
  }, [ errors ]);

  useEffect(() => {
    dispatch(clearGlobalErrors());
    dispatch(clearGlobalMessages());
  }, [ location ]);

  useLayoutEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const accessToken = searchParams.get('accessToken');

    if (accessToken) {
      dispatch(clearGlobalErrors());
      dispatch(tokenExchange({ accessToken }));
    }
  }, [ location ]);

  return (
    <div className='app'>
      <Loader />
      <ErrorPopup />
      <MessagePopup />
      <CommonHelmetWrapper />
      {auth && (<HeaderDemo items={NAV_ROUTES} />)}
      {!PAGES_WITHOUT_NAV.includes(pathname) && <TopBar />}
      <div className={auth ? 'app-content' : ''}>
        {auth && (<Sidebar items={NAV_ROUTES} />)}
        <ErrorBoundary>
          {renderRoutes(route.routes, { auth })}
        </ErrorBoundary>
        <Icons />
      </div>
    </div>
  );
};

export default {
  component: App,
};
