import React from 'react';

import RandomIcon from './Dashboard/RandomIcon';

/**
 * @param name
 * @param onClick
 * @returns {JSX.Element}
 * @constructor
 */
const SidebarLogoutForm = ({ name, onClick }) => (
  <div
    className='sidebar-logout-form'
  >
    <div>
      <RandomIcon />
    </div>
    <div className='sidebar-logout-form-right'>
      <div className='sidebar-logout-form-name'>{name}</div>
      <div>
        <button type='button' className='sidebar-logout-form-btn' onClick={onClick}>
          Logout
        </button>
      </div>
    </div>
  </div>
);

export default SidebarLogoutForm;
