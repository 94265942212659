import React from 'react';
import logo from 'assets/images/logo.svg';

/**
 * @returns {JSX.Element}
 * @constructor
 */
const SidebarLogo = () => {
  const handleLogoClick = () => {
    window.location.href = MILA_ECOM_HOST;
  };

  return (
    <div className='sidebar-header'>
      <div className='sidebar-header-logo'>
        <img src={logo} alt='Mila' onClick={handleLogoClick} style={{ cursor: 'pointer' }} />
      </div>
    </div>
  );
};

export default SidebarLogo;
