import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axios } from '../../../shared/clients';
import { STATUS } from '../../../shared/enums';
import { setPlans } from '../plans/plansSlice';
import { addGlobalError } from '../root/rootSlice';

/**
 * @type {{data: null, error: null, status: string}}
 */
export const initialState = {
  data: null,
  status: STATUS.IDLE,
  error: null,
};

export const patchFilterTypes = createAsyncThunk('filterTypesSlice/patchFilterTypes', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.patch('/order/details', payload);
    await dispatch(setPlans(response?.data));
    return response?.data;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

/**
 * @type {Slice<{data: null, error: null, status: string}, {}, string>}
 */
export const filterTypesSlice = createSlice({
  name: 'filterTypes',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(patchFilterTypes.pending, state => {
        state.status = STATUS.LOADING;
      })
      .addCase(patchFilterTypes.fulfilled, (state, action) => {
        state.status = STATUS.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(patchFilterTypes.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload;
      });
  },
});

export const selectFilterTypesData = state => state.filterTypes.data;
export const selectFilterTypesStatus = state => state.filterTypes.status;
export const selectFilterTypesError = state => state.filterTypes.error;

export default filterTypesSlice.reducer;
