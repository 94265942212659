import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import IconAccount from 'assets/images/rookieparents.svg';
import { selectPlansData, selectPlansStatus } from '../../features/plans/plansSlice';
import { FILTERS_ICONS, STATUS } from '../../../shared/enums';
import { randomIntFromInterval } from '../../../shared/utils';

const RandomIcon = () => {
  const plans = useSelector(selectPlansData);
  const plansStatus = useSelector(selectPlansStatus);
  const [ variant, setVariant ] = useState(null);

  useEffect(() => {
    if (plans?.length) {
      const [ plan ] = plans;
      const variants = plan.details?.map(item => item.variant);
      const randomId = randomIntFromInterval(0, variants.length - 1);
      setVariant(variants[randomId]);
    }
  }, [ plans ]);

  return (
    <div className={`random-icon random-icon-bg-${variant}`}>
      {
        !!([ STATUS.SUCCEEDED, STATUS.PREFETCHED ].includes(plansStatus) && plans?.length)
          && (() => {
            switch (variant) {
            case 'BS':
              return FILTERS_ICONS.BS;
            case 'CC':
              return FILTERS_ICONS.CC;
            case 'BB':
              return FILTERS_ICONS.BB;
            case 'RP':
              return FILTERS_ICONS.RP;
            case 'HW':
              return FILTERS_ICONS.HW;
            case 'MB':
              return FILTERS_ICONS.MB;
            case 'OR':
              return FILTERS_ICONS.OR;
            default:
              return null;
            }
          })()
      }
      {
        !!(![ STATUS.LOADING ].includes(plansStatus) && !plans?.length)
          && <img src={IconAccount} alt='Account icon' />
      }
    </div>
  );
};

export default RandomIcon;
