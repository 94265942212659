import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axios } from '../../../shared/clients';
import { STATUS } from '../../../shared/enums';
import { addGlobalError } from '../root/rootSlice';
import { setPlans } from '../plans/plansSlice';

/**
 * @type {{data: null, error: null, status: string}}
 */
export const initialState = {
  data: null,
  status: STATUS.IDLE,
  error: null,
};

export const patchAddress = createAsyncThunk('address/patchAddress', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.patch('/address', payload);
    await dispatch(setPlans(response?.data));
    return response?.data;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

/**
 * @type {Slice<{data: null, error: null, status: string}, {}, string>}
 */
export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    clearAddress: state => {
      state.data = initialState.data;
      state.status = initialState.status;
      state.error = initialState.error;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(patchAddress.pending, state => {
        state.status = STATUS.LOADING;
      })
      .addCase(patchAddress.fulfilled, (state, action) => {
        state.status = STATUS.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(patchAddress.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload;
      });
  },
});

export const { clearAddress } = addressSlice.actions;

export const selectAddressData = state => state.address.data;
export const selectAddressStatus = state => state.address.status;
export const selectAddressError = state => state.address.error;

export default addressSlice.reducer;
