import React from 'react';
import ErrorCloseImg from '../../assets/uploads/error-close.svg';
import ErrorCloseMobileImg from '../../assets/uploads/error-close-mobile.svg';

const MessagePopupItem = ({ message, idx, removeMessage }) => (message.show && (
  <div className={`message-popup ${message.show ? 'fade-in' : 'fade-out'} `}>
    <p className='message-popup-text'>{message.message}</p>
    <div className='message-popup-close'>
      <img src={ErrorCloseImg} alt='close' onClick={() => removeMessage(idx)} />
    </div>
    <div className='message-popup-close-mobile' onClick={() => removeMessage(idx)}>
      <img src={ErrorCloseMobileImg} alt='close' />
      <p className='message-popup-close-mobile__label'>tap to close</p>
    </div>
  </div>
)
);

export default MessagePopupItem;
