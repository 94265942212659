import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axios } from '../../../shared/clients';
import { STATUS } from '../../../shared/enums';
import { addGlobalError } from '../root/rootSlice';

/**
 * @type {{patchPlan: {error: null, status: string}, fetchPlans: {data: null, error: null, status: string}}}
 */
export const initialState = {
  data: null,
  status: STATUS.IDLE,
  error: null,
};

export const fetchPlans = createAsyncThunk('plans/fetchPlans', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.get('/plans');
    return response?.data;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

/**
 * @type {Slice<{data: null, error: null, status: string}, {clearShipments: reducers.clearShipments}, string>}
 */
export const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    clearPlans: state => {
      state.data = initialState.data;
      state.status = initialState.status;
      state.error = initialState.error;
    },
    setPlan: (state, action) => {
      const planId = state.data.findIndex(value => ((value.id === action.payload.id)));
      if (!Number.isNaN(planId)) state.data[planId] = action.payload;
    },
    setPlans: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPlans.pending, state => {
        state.status = STATUS.LOADING;
      })
      .addCase(fetchPlans.fulfilled, (state, action) => {
        state.status = IS_SERVER ? STATUS.PREFETCHED : STATUS.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(fetchPlans.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload;
      });
  },
});

export const { clearPlans, setPlan, setPlans } = plansSlice.actions;

export const selectPlansData = state => state.plans.data;
export const selectPlansStatus = state => state.plans.status;
export const selectPlansError = state => state.plans.error;

export default plansSlice.reducer;
