import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeGlobalMessage, selectMessages } from '../features/root/rootSlice';
import MessagePopupItem from './MessagePopupItem';

const MessagePopup = () => {
  const [ show, setShow ] = useState(false);
  const dispatch = useDispatch();

  const messages = useSelector(selectMessages);
  const [ messagesViewMap, setMessagesViewMap ] = useState([]);

  const removeMessage = idx => {
    dispatch(removeGlobalMessage(idx));
  };

  useEffect(() => {
    const output = [];
    messages.forEach(value => {
      output.push({
        ...value,
        show: true,
      });
    });
    setMessagesViewMap(output);
  }, [ messages ]);

  useEffect(() => {
    setShow(messagesViewMap.some(message => message.show));
  }, [ messagesViewMap ]);

  useEffect(() => {
    messages.forEach((message, idx) => {
      setTimeout(() => {
        dispatch(removeGlobalMessage(idx));
      }, 5000);
    });
  }, [ messages, dispatch ]);

  return (show && (
    <div className='message-popup-bg'>
      {messagesViewMap.length
      && messagesViewMap.map((message, idx) => <MessagePopupItem message={message} idx={idx} removeMessage={removeMessage} />)}
    </div>
  )
  );
};

export default MessagePopup;
