import React from 'react';

const Icons = () => (
  <svg xmlns='http://www.w3.org/2000/svg' style={{ height: 0, position: 'absolute', width: 0 }}>
    <defs>
      <symbol id='icon-plus' viewBox='0 0 32 32'>
        <path
          d='M16.001 1.625c1.663 0 3.030 1.269 3.185 2.892l0.015 0.308v22.349c0 1.767-1.433 3.2-3.2 3.2-1.663 0-3.030-1.269-3.185-2.892l-0.015-0.308v-22.349c0-1.767 1.433-3.2 3.2-3.2z'
        />
        <path
          d='M27.175 12.8c1.767 0 3.2 1.433 3.2 3.2 0 1.663-1.269 3.030-2.892 3.185l-0.308 0.015h-22.349c-1.767 0-3.2-1.433-3.2-3.2 0-1.663 1.269-3.030 2.892-3.185l0.308-0.015z'
        />
      </symbol>
      <symbol id='icon-minus' viewBox='0 0 149 32'>
        <path
          d='M125.462 0c8.837 0 16 7.163 16 16 0 8.317-6.345 15.151-14.459 15.927l-1.541 0.073h-101.587c-8.837 0-16-7.163-16-16 0-8.317 6.345-15.151 14.459-15.927l1.541-0.073z'
        />
      </symbol>
      <symbol id='icon-tick' viewBox='0 0 44 32'>
        <path
          d='M36.822 2.265c1.468-1.415 3.805-1.373 5.221 0.095 1.327 1.376 1.373 3.517 0.164 4.945l-0.259 0.276-22.974 22.154c-1.341 1.293-3.416 1.374-4.848 0.242l-0.278-0.242-11.487-11.077c-1.468-1.415-1.51-3.753-0.095-5.221 1.327-1.376 3.464-1.5 4.936-0.343l0.285 0.248 8.923 8.605z'
        />
      </symbol>
      <symbol id='icon-basket' viewBox='0 0 41 32'>
        <path
          d='M35.288 7.619h-29.433c-2.974 0-5.156 2.795-4.435 5.68l3.81 15.238c0.509 2.035 2.337 3.463 4.435 3.463h21.814c2.098 0 3.926-1.428 4.435-3.463l3.81-15.238c0.721-2.885-1.461-5.68-4.435-5.68zM31.478 27.429h-21.814l-3.81-15.238h29.433z'
        />
        <path
          d='M25.905 0h-10.667c-2.525 0-4.571 2.047-4.571 4.571v7.619h19.81v-7.619c0-2.525-2.047-4.571-4.571-4.571zM15.238 4.571h10.667v3.048h-10.667z'
        />
      </symbol>
      <symbol id='icon-arrow-down' viewBox='0 0 60 32'>
        <path
          d='M48.342 3.244c2.627-2.020 6.394-1.529 8.414 1.097 1.902 2.472 1.578 5.954-0.656 8.040l-0.441 0.374-22 16.923c-1.991 1.531-4.703 1.649-6.805 0.353l-0.512-0.353-22-16.923c-2.627-2.020-3.118-5.787-1.097-8.414 1.902-2.472 5.35-3.053 7.939-1.428l0.475 0.331 18.342 14.108z'
        />
      </symbol>
      <symbol id='icon-info' viewBox='0 0 32 32'>
        <path
          d='M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16s-7.163-16-16-16zM16 3.2c7.069 0 12.8 5.731 12.8 12.8s-5.731 12.8-12.8 12.8c-7.069 0-12.8-5.731-12.8-12.8s5.731-12.8 12.8-12.8z'
        />
        <path
          d='M18.959 22.1l-0.253 1.006c-0.759 0.291-1.364 0.51-1.816 0.659-0.452 0.156-0.977 0.235-1.575 0.235-0.92 0-1.636-0.216-2.149-0.648-0.506-0.44-0.759-0.995-0.759-1.665 0-0.261 0.019-0.525 0.057-0.793 0.038-0.276 0.1-0.585 0.184-0.928l0.943-3.263c0.084-0.313 0.153-0.607 0.207-0.883 0.061-0.283 0.092-0.54 0.092-0.771 0-0.417-0.088-0.708-0.264-0.872s-0.513-0.246-1.011-0.246c-0.245 0-0.498 0.037-0.759 0.112-0.253 0.075-0.471 0.145-0.655 0.212l0.253-1.006c0.621-0.246 1.215-0.454 1.782-0.626 0.567-0.179 1.103-0.268 1.609-0.268 0.912 0 1.613 0.216 2.104 0.648 0.498 0.425 0.747 0.98 0.747 1.665 0 0.142-0.019 0.391-0.057 0.749-0.031 0.358-0.092 0.685-0.184 0.983l-0.942 3.252c-0.077 0.261-0.146 0.559-0.207 0.894s-0.092 0.588-0.092 0.76c0 0.432 0.1 0.726 0.299 0.883s0.544 0.235 1.034 0.235c0.23 0 0.49-0.037 0.782-0.112 0.291-0.082 0.502-0.153 0.632-0.212zM19.2 8.456c0 0.566-0.222 1.050-0.667 1.453-0.437 0.395-0.965 0.592-1.586 0.592s-1.153-0.197-1.598-0.592c-0.444-0.402-0.667-0.887-0.667-1.453s0.222-1.050 0.667-1.453c0.444-0.402 0.977-0.603 1.598-0.603s1.149 0.201 1.586 0.603c0.444 0.402 0.667 0.887 0.667 1.453z'
        />
      </symbol>
      <symbol id='icon-filters' viewBox='0 0 40 32'>
        <path
          d='M34.047 5.135v20.614h-29.247v-20.614h29.247zM4.316 0.335c-2.384 0-4.316 1.932-4.316 4.316v21.581c0 2.384 1.932 4.316 4.316 4.316h30.214c2.384 0 4.316-1.932 4.316-4.316v-21.581c0-2.384-1.932-4.316-4.316-4.316h-30.214z'
        />
        <path d='M10.4 28.8v-28.8h4.8v28.8h-4.8z' />
        <path d='M23.2 28.8v-28.8h4.8v28.8h-4.8z' />
      </symbol>
      <symbol id='icon-share' viewBox='0 0 32 32'>
        <path
          d='M4.174 27.826l0-23.652h23.652v23.652h-23.652zM0 27.826c-0 2.305 1.869 4.174 4.174 4.174h23.652c2.305 0 4.174-1.869 4.174-4.174v-23.652c0-2.305-1.869-4.174-4.174-4.174h-23.652c-2.305 0-4.174 1.869-4.174 4.174l-0 23.652z'
        />
        <path
          d='M20.954 11.046c0.815 0.815 0.815 2.136 0 2.951l-8.348 8.348c-0.815 0.815-2.136 0.815-2.951 0s-0.815-2.136 0-2.951l8.348-8.348c0.815-0.815 2.137-0.815 2.951 0z'
        />
        <path
          d='M10.435 11.13c0-1.153 0.934-2.087 2.087-2.087h10.435v10.435c0 1.153-0.934 2.087-2.087 2.087s-2.087-0.934-2.087-2.087v-6.261h-6.261c-1.153 0-2.087-0.934-2.087-2.087z'
        />
      </symbol>
      <symbol id='icon-reviews' viewBox='0 0 31 32'>
        <path
          d='M26.435 4.174v15.077h-9.751c-1.792 0-3.536 0.577-4.975 1.644l-7.535 5.592v-22.313h22.261zM4.174 0c-2.305 0-4.174 1.869-4.174 4.174v22.313c0 3.43 3.907 5.396 6.661 3.352l7.535-5.592c0.719-0.534 1.592-0.822 2.487-0.822h9.751c2.305 0 4.174-1.869 4.174-4.174v-15.077c0-2.305-1.869-4.174-4.174-4.174h-22.261z'
        />
      </symbol>
      <symbol id='icon-unit' viewBox='0 0 24 32'>
        <path
          d='M20.943 25.824c-5.829-1.067-11.804-1.067-17.633 0v-22.031c0-0.026 0.005-0.040 0.009-0.049 0.005-0.012 0.015-0.027 0.031-0.041s0.031-0.023 0.044-0.027c0.009-0.003 0.023-0.007 0.049-0.004 5.774 0.581 11.592 0.581 17.367 0 0.026-0.003 0.040 0.001 0.049 0.004 0.012 0.004 0.028 0.013 0.044 0.027s0.026 0.029 0.031 0.041c0.004 0.009 0.009 0.022 0.009 0.049v22.031zM3.775 0.379c-2.021-0.203-3.775 1.383-3.775 3.414v23.783c0 1.199 1.11 2.091 2.281 1.832 6.486-1.433 13.206-1.433 19.692 0 1.171 0.259 2.281-0.633 2.281-1.832v-23.783c0-2.031-1.754-3.617-3.775-3.414-5.554 0.558-11.15 0.558-16.704 0z'
        />
        <path
          d='M2.304 27.696c-0.052-0.334 0.207-0.636 0.545-0.636h3.127c0.338 0 0.597 0.302 0.545 0.636l-0.51 3.31c-0.041 0.269-0.273 0.468-0.545 0.468h-2.106c-0.272 0-0.504-0.198-0.545-0.468l-0.51-3.31z'
        />
        <path
          d='M17.752 27.696c-0.052-0.334 0.207-0.636 0.545-0.636h3.127c0.338 0 0.597 0.302 0.545 0.636l-0.51 3.31c-0.041 0.269-0.273 0.468-0.545 0.468h-2.106c-0.272 0-0.504-0.198-0.545-0.468l-0.51-3.31z'
        />
      </symbol>
      <symbol id='icon-twitter' viewBox='0 0 39 32'>
        <path
          d='M39.035 3.811c-1.442 0.638-2.982 1.073-4.604 1.271 1.652-1.002 2.923-2.582 3.525-4.467-1.55 0.91-3.267 1.573-5.096 1.942-1.46-1.573-3.541-2.557-5.852-2.557-4.427 0-8.017 3.614-8.017 8.066 0 0.64 0.073 1.255 0.207 1.844-6.665-0.317-12.572-3.538-16.525-8.409-0.696 1.184-1.085 2.561-1.085 4.060 0 2.805 1.418 5.271 3.566 6.719-1.315-0.043-2.552-0.407-3.631-1.011v0.1c0 3.912 2.759 7.175 6.43 7.918-0.673 0.182-1.383 0.281-2.112 0.281-0.512 0-1.002-0.049-1.493-0.141 1.028 3.204 3.984 5.54 7.502 5.605-2.738 2.164-6.207 3.453-9.944 3.453-0.636 0-1.269-0.038-1.907-0.11 3.567 2.287 7.77 3.624 12.315 3.624 14.754 0 22.812-12.297 22.812-22.943 0-0.343 0-0.689-0.025-1.033 1.566-1.13 2.933-2.559 4.009-4.18l-0.077-0.033z'
        />
      </symbol>
      <symbol id='icon-facebook' viewBox='0 0 24 24'>
        <path
          d='M23.989 12q0-2.477-0.929-4.644t-2.589-3.828-3.828-2.589-4.644-0.929-4.644 0.929-3.828 2.589-2.589 3.828-0.929 4.644q0 2.195 0.704 4.137t2.026 3.518 3.068 2.645 3.771 1.463v-8.162h-2.364v-3.602h2.364v-2.983q0-1.182 0.591-2.139t1.52-1.52 2.111-0.563h2.983v3.602h-2.364q-0.507 0-0.872 0.366t-0.366 0.816v2.42h3.602v3.602h-3.602v8.33q2.308-0.225 4.278-1.238t3.434-2.617 2.28-3.659 0.816-4.419z'
        />
      </symbol>
      <symbol id='icon-account' viewBox='0 0 27 32'>
        <path
          d='M13.283 13.683c-4.717 0-8.873 3.104-10.211 7.627l-2.325 7.86c-0.373 1.259 0.571 2.523 1.885 2.523h21.192c1.272 0 2.195-1.211 1.858-2.437l-2.13-7.747c-1.271-4.622-5.474-7.826-10.268-7.826zM13.628 16.891c3.208 0.148 5.979 2.344 6.837 5.466l1.687 6.136h-17.869l1.857-6.274c0.936-3.164 3.843-5.335 7.143-5.335l0.345 0.008z'
        />
        <path
          d='M13.176 0c-4.605 0-8.338 3.733-8.338 8.338s3.733 8.338 8.338 8.338c4.605 0 8.338-3.733 8.338-8.338s-3.733-8.338-8.338-8.338zM13.176 3.2c2.838 0 5.138 2.3 5.138 5.138s-2.3 5.138-5.138 5.138c-2.838 0-5.138-2.3-5.138-5.138s2.3-5.138 5.138-5.138z'
        />
      </symbol>
      <symbol id='icon-devices' viewBox='0 0 24 32'>
        <path
          d='M3.566 1.197c-1.909-0.192-3.566 1.307-3.566 3.225v22.469c0 1.133 1.048 1.975 2.154 1.731 6.128-1.354 12.477-1.354 18.605-0 1.107 0.244 2.155-0.598 2.155-1.731v-22.469c0-1.919-1.657-3.417-3.566-3.225-5.247 0.528-10.534 0.528-15.782 0zM19.725 3.966c0.226 0.026 0.406 0.218 0.406 0.456l-0 21.231-0.479-0.091c-5.419-0.983-10.971-0.983-16.39 0l-0.479 0.090v-21.23c0-0.272 0.235-0.484 0.505-0.457 5.432 0.546 10.906 0.546 16.338 0l0.099 0.001z'
        />
        <path
          d='M1.521 27.179c-0.068-0.423 0.258-0.806 0.687-0.806h2.97c0.428 0 0.755 0.383 0.687 0.806l-0.485 3.009c-0.054 0.337-0.345 0.585-0.687 0.585h-1.999c-0.341 0-0.632-0.248-0.687-0.585l-0.485-3.009z'
        />
        <path
          d='M16.826 27.179c-0.068-0.423 0.258-0.806 0.687-0.806h2.97c0.428 0 0.755 0.383 0.687 0.806l-0.485 3.009c-0.054 0.337-0.345 0.585-0.687 0.585h-1.999c-0.341 0-0.633-0.248-0.687-0.585l-0.485-3.009z'
        />
      </symbol>
      <symbol id='icon-arrow-down-thin' viewBox='0 0 59 32'>
        <path
          d='M52.070 0.948c2.001-1.539 4.871-1.165 6.411 0.836 1.421 1.847 1.211 4.435-0.403 6.033l-0.433 0.378-25.143 19.341c-1.479 1.138-3.48 1.251-5.063 0.341l-0.511-0.341-25.143-19.341c-2.001-1.539-2.376-4.41-0.836-6.411 1.421-1.847 3.976-2.308 5.934-1.158l0.476 0.321 22.356 17.196 22.356-17.196z'
        />
      </symbol>
      <symbol id='icon-wifi' viewBox='0 0 47 32'>
        <path
          d='M14.635 14.513c5.115-2.866 11.424-2.866 16.539 0 2.051 1.149 3.811 2.704 5.175 4.558 0.714 0.971 0.506 2.336-0.465 3.050s-2.336 0.506-3.050-0.465c-0.995-1.353-2.284-2.491-3.792-3.337-3.79-2.123-8.483-2.123-12.273 0-1.466 0.821-2.724 1.92-3.708 3.223-0.726 0.962-2.094 1.154-3.056 0.428s-1.153-2.094-0.428-3.056c1.347-1.786 3.066-3.286 5.059-4.402z'
        />
        <path
          d='M9.896 4.231c8.039-4.672 17.967-4.672 26.006-0 3.631 2.11 6.672 5.055 8.891 8.577 0.642 1.019 0.337 2.367-0.683 3.009s-2.367 0.337-3.009-0.683c-1.846-2.928-4.373-5.376-7.392-7.131-6.684-3.884-14.937-3.884-21.621 0-2.99 1.738-5.498 4.155-7.338 7.046-0.647 1.016-1.996 1.316-3.012 0.669s-1.316-1.996-0.669-3.012c2.214-3.477 5.231-6.386 8.827-8.476z'
        />
        <path
          d='M22.901 23.273c2.163 0.022 3.901 1.789 3.888 3.953s-1.773 3.908-3.936 3.904c-2.163-0.004-3.916-1.757-3.921-3.92 0.009-2.183 1.786-3.945 3.969-3.937z'
        />
      </symbol>
      <symbol id='icon-automagic' viewBox='0 0 34 32'>
        <path
          d='M18.034 0c-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16s-7.163-16-16-16zM18.034 3.2c7.069 0 12.8 5.731 12.8 12.8s-5.731 12.8-12.8 12.8c-7.069 0-12.8-5.731-12.8-12.8s5.731-12.8 12.8-12.8z'
        />
        <path
          d='M11.53 22.401h2.918l0.941-3.053h4.973l0.941 3.053h3.245l-4.454-13.728h-4.051l-4.512 13.728zM17.885 11.207l1.805 5.933h-3.629l1.824-5.933z'
        />
      </symbol>
      <symbol id='icon-paper-plane' viewBox='0 0 36 32'>
        <path
          d='M30.717 0.159l-25.988 1.368c-3.302 0.174-4.6 4.366-1.973 6.375l10.791 8.252 3.044 11.569c0.846 3.212 5.242 3.614 6.656 0.609l10.874-23.108c1.145-2.432-0.72-5.206-3.404-5.065zM4.916 5.077l25.988-1.368-10.874 23.108-3.044-11.569c-0.201-0.764-0.651-1.44-1.279-1.92l-10.791-8.252z'
        />
        <path d='M30.98 1.21l2.039 2.913-17.778 12.444-2.039-2.913z' />
      </symbol>
      <symbol id='icon-filter' viewBox='0 0 35 24'>
        <path d='M35.2 0v4.8h-35.2v-4.8z' />
        <path d='M29.44 9.6v4.8h-23.040v-4.8z' />
        <path d='M23.679 19.2v4.8h-11.52v-4.8z' />
      </symbol>
      <symbol id='icon-arrow-dropdown' viewBox='0 0 53 32'>
        <path
          d='M41.694 1.434c2.153-2.010 5.528-1.893 7.538 0.26 1.855 1.988 1.899 5.016 0.201 7.051l-0.461 0.487-20 18.667c-1.878 1.753-4.707 1.899-6.744 0.438l-0.535-0.438-20-18.667c-2.153-2.010-2.27-5.385-0.26-7.538 1.855-1.988 4.874-2.24 7.021-0.686l0.517 0.426 16.361 15.264 16.361-15.264z'
        />
      </symbol>
    </defs>
  </svg>
);

export default Icons;
