import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axios } from '../../../shared/clients';
import { STATUS } from '../../../shared/enums';
import { addGlobalError } from '../root/rootSlice';
import { setPlans } from '../plans/plansSlice';

/**
 * @type {{data: null, error: null, status: string}}
 */
export const initialState = {
  data: null,
  status: STATUS.IDLE,
  error: null,
};

/**
 * @type {AsyncThunk<unknown, void, AsyncThunkConfig>}
 */
export const postBilling = createAsyncThunk('billing/postBilling', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post('/billing', payload);
    await dispatch(setPlans(response?.data));
    return response?.data;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

/**
 * @type {Slice<{data: null, error: null, status: string}, {clearBilling: reducers.clearBilling}, string>}
 */
export const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    clearBilling: state => {
      state.data = initialState.data;
      state.status = initialState.status;
      state.error = initialState.error;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(postBilling.pending, state => {
        state.status = STATUS.LOADING;
      })
      .addCase(postBilling.fulfilled, (state, action) => {
        state.status = STATUS.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(postBilling.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload;
      });
  },
});

export const { clearBilling } = billingSlice.actions;

export const selectBillingData = state => state.billing.data;
export const selectBillingStatus = state => state.billing.status;
export const selectBillingError = state => state.billing.error;

export default billingSlice.reducer;
