import App from '../client/App';

import {
  Account,
  Login,
  NotFound,
  Dashboard,
  History,
  Referral,
} from '../client/pages';

export default [
  {
    ...App,
    routes: [
      {
        ...Login,
        path: '/',
        exact: true,
        meta: {
          title: 'My Mila Account — Get Started',
        },
      },
      {
        ...Dashboard,
        path: '/dashboard',
        exact: true,
        meta: {
          title: 'My Dashboard',
        },
      },
      {
        ...Account,
        path: '/account',
        exact: true,
        meta: {
          title: 'My Mila Account',
        },
      },
      {
        ...History,
        path: '/history',
        exact: true,
        meta: {
          title: 'My Mila Order History',
        },
      },
      {
        ...Referral,
        path: '/referral',
        exact: true,
        meta: {
          title: 'My Mila Rewards',
        },
      },
      {
        ...NotFound,
      },
    ],
  },
];
