import React from 'react';
import ErrorCloseImg from '../../assets/uploads/error-close.svg';
import ErrorCloseMobileImg from '../../assets/uploads/error-close-mobile.svg';

const ErrorPopupItem = ({ error, idx, removeError }) => (error.show && (
  <div className={`error-popup ${error.show ? 'fade-in' : 'fade-out'} `}>
    <p className='error-popup-text'>{error.error || error.message}</p>
    <div className='error-popup-close'>
      <img src={ErrorCloseImg} alt='close' onClick={() => removeError(idx)} />
    </div>
    <div className='error-popup-close-mobile' onClick={() => removeError(idx)}>
      <img src={ErrorCloseMobileImg} alt='close' />
      <p className='error-popup-close-mobile__label'>tap to close</p>
    </div>
  </div>
)
);

export default ErrorPopupItem;
