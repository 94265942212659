import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axios } from '../../../shared/clients';
import { STATUS } from '../../../shared/enums';
import { setPlans } from '../plans/plansSlice';
import { addGlobalError } from '../root/rootSlice';

/**
 * @type {{data: null, error: null, status: string}}
 */
export const initialState = {
  data: null,
  status: STATUS.IDLE,
  error: null,
};

export const expediteRefill = createAsyncThunk('expediteRefill/expediteRefill', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post('/refill/expedite', payload);
    await dispatch(setPlans(response?.data));
    return response?.data;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

export const expediteRefillImmediate = createAsyncThunk('expediteRefill/expediteRefillImmediate', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post('/refill/expedite-immediate', payload);
    await dispatch(setPlans(response?.data));
    return response?.data;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

/**
 * @type {Slice<{data: null, error: null, status: string}, {}, string>}
 */
export const expediteRefillSlice = createSlice({
  name: 'expediteRefill',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(expediteRefill.pending, state => {
        state.status = STATUS.LOADING;
      })
      .addCase(expediteRefill.fulfilled, (state, action) => {
        state.status = STATUS.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(expediteRefill.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload;
      })
      .addCase(expediteRefillImmediate.pending, state => {
        state.status = STATUS.LOADING;
      })
      .addCase(expediteRefillImmediate.fulfilled, (state, action) => {
        state.status = STATUS.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(expediteRefillImmediate.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload;
      });
  },
});

export const selectExpediteRefillData = state => state.expediteRefill.data;
export const selectExpediteRefillStatus = state => state.expediteRefill.status;
export const selectExpediteRefillError = state => state.expediteRefill.error;

export default expediteRefillSlice.reducer;
