import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axios } from '../../../shared/clients';
import { STATUS } from '../../../shared/enums';
import { addGlobalError } from '../root/rootSlice';

/**
 * @type {{data: null, error: null, status: string}}
 */
export const initialState = {
  data: null,
  status: STATUS.IDLE,
  error: null,
};

export const fetchRegionalPrices = createAsyncThunk('regionalPrices/fetchRegionalPrices', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.get('/regional-prices', { params: { region: payload.join(',') }});
    return response?.data;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

/**
 * @type {Slice<{data: null, error: null, status: string}, {clearShipments: reducers.clearShipments}, string>}
 */
export const regionalPricesSlice = createSlice({
  name: 'regionalPrices',
  initialState,
  reducers: {
    clearRegionalPrices: state => {
      state.data = initialState.data;
      state.status = initialState.status;
      state.error = initialState.error;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchRegionalPrices.pending, state => {
        state.status = STATUS.LOADING;
      })
      .addCase(fetchRegionalPrices.fulfilled, (state, action) => {
        state.status = IS_SERVER ? STATUS.PREFETCHED : STATUS.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(fetchRegionalPrices.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload;
      });
  },
});

export const { clearRegionalPrices } = regionalPricesSlice.actions;

export const selectRegionalPricesData = state => state.regionalPrices.data;
export const selectRegionalPricesStatus = state => state.regionalPrices.status;
export const selectRegionalPricesError = state => state.regionalPrices.error;

export default regionalPricesSlice.reducer;
