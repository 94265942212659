/**
 * getChangedValues
 *
 * @param {*} obj1
 * @param {*} obj2
 * @return {*}
 */
const getChangedValues = (values, initialValues) => Object
  .entries(values)
  .reduce((acc, [ key, value ]) => {
    const hasChanged = initialValues[key] !== value;

    if (hasChanged) {
      acc[key] = value;
    }

    return acc;
  }, {});

export default getChangedValues;
