import React from 'react';
import { useSelector } from 'react-redux';
import { selectAuthError } from '../features/auth/authSlice';

const LoginForm = props => {
  const {
    validation,
    preFillEmail,
    onChange,
    placeholders,
  } = props;

  const error = useSelector(selectAuthError);

  return (
    <form>
      <div className={`form-group ${error || !validation.email ? 'error' : ''}`}>
        <input
          id='user-email'
          type='email'
          className='form-control'
          placeholder={placeholders.email}
          required
          defaultValue={preFillEmail || undefined}
          onChange={onChange}
        />
        <label htmlFor='user-email'>Email address</label>
      </div>
      <div className={`form-group ${error || !validation.password ? 'error' : ''}`}>
        <input
          id='user-password'
          type='password'
          className='form-control'
          placeholder={placeholders.password}
          required
          onChange={onChange}
        />
        <label htmlFor='user-password'>Password</label>
      </div>
    </form>
  );
};

export default LoginForm;
