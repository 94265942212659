/**
 *  convertToOrdinal
 *
 * @param {*} number
 * @return {*}
 */

function convertToOrdinal(number) {
  if (typeof number !== 'number' || Number.isNaN(number)) {
    throw new Error('Input must be a valid number');
  }

  const suffixes = {
    1: 'st',
    2: 'nd',
    3: 'rd',
  };

  const lastDigit = number % 10;
  const lastTwoDigits = number % 100;

  let suffix = 'th';

  if (lastDigit === 1 && lastTwoDigits !== 11) {
    suffix = suffixes[1];
  } else if (lastDigit === 2 && lastTwoDigits !== 12) {
    suffix = suffixes[2];
  } else if (lastDigit === 3 && lastTwoDigits !== 13) {
    suffix = suffixes[3];
  }

  return number + suffix;
}

export default convertToOrdinal;
