import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axios } from '../../../shared/clients';
import { STATUS } from '../../../shared/enums';
import { addGlobalError } from '../root/rootSlice';

/**
 * @type {{sendRefillConfirmationStatus: string, sendOrderConfirmationStatus: string, data: null, error: null, status: string, sendOrderConfirmationError: null, sendRefillConfirmationError: null}}
 */
export const initialState = {
  data: null,
  status: STATUS.IDLE,
  error: null,
  sendOrderConfirmationStatus: STATUS.IDLE,
  sendOrderConfirmationError: null,
  sendRefillConfirmationStatus: STATUS.IDLE,
  sendRefillConfirmationError: null,
};

export const fetchShipments = createAsyncThunk('shipment/fetchShipments', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.get('/shipments');
    return response?.data;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

export const sendOrderConfirmation = createAsyncThunk('shipment/sendOrderConfirmation', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post('/send-order-confirmation', payload);
    return response?.data;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

export const sendRefillConfirmation = createAsyncThunk('shipment/sendRefillConfirmation', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post('/send-refill-confirmation', payload);
    return response?.data;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

/**
 * @type {Slice<{data: null, error: null, status: string}, {clearShipments: reducers.clearShipments}, string>}
 */
export const shipmentSlice = createSlice({
  name: 'shipment',
  initialState,
  reducers: {
    clearShipments: state => {
      state.data = initialState.data;
      state.status = initialState.status;
      state.error = initialState.error;
      state.sendOrderConfirmationStatus = initialState.sendOrderConfirmationStatus;
      state.sendOrderConfirmationError = initialState.sendOrderConfirmationError;
      state.sendRefillConfirmationStatus = initialState.sendRefillConfirmationStatus;
      state.sendRefillConfirmationError = initialState.sendRefillConfirmationError;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchShipments.pending, state => {
        state.status = STATUS.LOADING;
      })
      .addCase(fetchShipments.fulfilled, (state, action) => {
        state.status = IS_SERVER ? STATUS.PREFETCHED : STATUS.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(fetchShipments.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload;
      })
      .addCase(sendOrderConfirmation.pending, state => {
        state.sendOrderConfirmationStatus = STATUS.LOADING;
      })
      .addCase(sendOrderConfirmation.fulfilled, state => {
        state.sendOrderConfirmationStatus = STATUS.SUCCEEDED;
      })
      .addCase(sendOrderConfirmation.rejected, (state, action) => {
        state.sendOrderConfirmationStatus = STATUS.FAILED;
        state.sendOrderConfirmationError = action.payload;
      })
      .addCase(sendRefillConfirmation.pending, state => {
        state.sendRefillConfirmationStatus = STATUS.LOADING;
      })
      .addCase(sendRefillConfirmation.fulfilled, state => {
        state.sendRefillConfirmationStatus = STATUS.SUCCEEDED;
      })
      .addCase(sendRefillConfirmation.rejected, (state, action) => {
        state.sendRefillConfirmationStatus = STATUS.FAILED;
        state.sendRefillConfirmationError = action.payload;
      });
  },
});

export const { clearShipments } = shipmentSlice.actions;

export const selectShipmentData = state => state.shipment.data;
export const selectShipmentStatus = state => state.shipment.status;
export const selectShipmentError = state => state.shipment.error;

export const selectSendOrderConfirmationStatus = state => state.shipment.sendOrderConfirmationStatus;
export const selectSendOrderConfirmationError = state => state.shipment.sendOrderConfirmationError;

export const selectSendRefillConfirmationStatus = state => state.shipment.sendRefillConfirmationStatus;
export const selectSendRefillConfirmationError = state => state.shipment.sendRefillConfirmationError;

export default shipmentSlice.reducer;
