/**
 * @param price
 * @return formatted price
 */
const formatPrice = price => {
  let formattedPrice = '';

  if (price) {
    const trimmedPrice = (`${price}`).replace(/[^\d.]/g, '');
    const parts = trimmedPrice.split('.');
    const integerPart = parts[0] || '0';
    const decimalPart = parts[1] || '00';
    const formattedDecimalPart = decimalPart.length > 2
      ? decimalPart.slice(0, 2)
      : decimalPart.padEnd(2, '0');
    formattedPrice = `${integerPart}.${formattedDecimalPart}`;
  }

  return formattedPrice;
};

export default formatPrice;
