import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import DashboardHistoryPlan from './DashboardHistoryPlan';
import { NAV_ROUTES } from '../../shared/constants';

const AutoRefillHistoryEmpty = () => (
  <p className='auto-refill-history__empty'>
    We don’t see anything in your
    {' '}
    <span className='nowrap'>Auto-Refill</span>
    {' '}
    history yet. Please watch this space for future orders.
  </p>
);

const UpcomingPlansWrapper = props => {
  const { plans } = props;
  return (
    <>
      {plans}
      <Link to={NAV_ROUTES[1].pathname}>
        <button type='button' className='btn btn---secondary-white'>View Your Order History</button>
      </Link>
    </>
  );
};

const DashboardHistory = ({ refillHistory }) => {
  const [ plans, setPlans ] = useState(null);

  useEffect(() => {
    if (refillHistory) {
      setPlans(refillHistory.map(plan => (
        <DashboardHistoryPlan plan={plan} />
      )));
    }
  }, [ refillHistory ]);

  return (
    <div className='upcoming auto-refill-history'>
      <h2 className='upcoming-title'>
        Order History
      </h2>
      {refillHistory?.length ? <UpcomingPlansWrapper plans={plans} /> : <AutoRefillHistoryEmpty />}
    </div>
  );
};

export default DashboardHistory;
