import React, {
  useEffect, useLayoutEffect, useMemo, useState,
} from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  AuthValidationWrapper,
} from '../components';
import DashboardHistory from '../components/DashboardHistory';
import {
  clearPlans,
  fetchPlans,
  selectPlansData,
  selectPlansError,
  selectPlansStatus,
} from '../features/plans/plansSlice';
import { SOURCE, STATUS } from '../../shared/enums';
import { selectAuthData } from '../features/auth/authSlice';
import {
  fetchRefillHistory, clearRefillHistory, selectRefillHistoryData, selectRefillHistoryStatus,
} from '../features/refill/refillSlice';
import {
  clearRegionalPrices, fetchRegionalPrices, selectRegionalPricesStatus,
} from '../features/regionalPrices/regionalPricesSlice';
import AutoRefillLogo from '../components/Dashboard/AutoRefillLogo';
import UserMenuMobile from '../components/UserMenuMobile';
import { getPlanRegion } from '../../shared/utils';
import { clearQaToken, selectQaTokensData } from '../features/plans/qaTokenSlice';
import { patchPlan } from '../features/plans/patchPlanSlice';

const CustomerFallback = loadable(() => import('../components/CustomerFallback'));
const StandaloneFilters = loadable(() => import('../components/StandaloneFilters'));
const NoActivePlans = loadable(() => import('../components/Dashboard/NoActivePlans'));
const ExclusiveMebmers = loadable(() => import('../components/Dashboard/ExclusiveMebmers'));
const ModalPaymentMethodRedirect = loadable(() => import('../components/ModalPaymentMethodRedirect'));

const AutoRefill = loadable(() => import(/* webpackPrefetch: true */ '../components/Dashboard/AutoRefill'));

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const NoActivePlansWrapper = props => {
  const { activePlans, inactivePlans, qaTokensData } = props;
  const [ validPlan, setValidPlan ] = useState(null);
  const [ showModalPaymentMethodRedirect, setShowModalPaymentMethodRedirect ] = useState(false);

  useEffect(() => {
    if (inactivePlans) {
      const valid = inactivePlans.filter(plan => {
        const region = getPlanRegion(plan);
        return (region?.country !== 'SG');
      });

      if (valid.length) {
        const [ plan ] = valid;
        setValidPlan(plan);
      }
    }
  }, [ inactivePlans ]);

  useEffect(() => {
    if (qaTokensData) {
      setShowModalPaymentMethodRedirect(true);
    }
  }, [ qaTokensData ]);

  return !activePlans?.length && inactivePlans?.length
    ? (
      <>
        <NoActivePlans plan={validPlan} />
        <StandaloneFilters />
        <ExclusiveMebmers />
        <ModalPaymentMethodRedirect
          show={showModalPaymentMethodRedirect}
          setShow={setShowModalPaymentMethodRedirect}
          activePlan={validPlan}
        />
      </>
    ) : (
      <>
        <CustomerFallback />
        <StandaloneFilters />
      </>
    );
};

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Dashboard = props => {
  const dispatch = useDispatch();
  const location = useLocation();
  const qaTokensData = useSelector(selectQaTokensData);
  const auth = useSelector(selectAuthData);
  const plans = useSelector(selectPlansData);
  const refillHistory = useSelector(selectRefillHistoryData);
  const plansStatus = useSelector(selectPlansStatus);
  const plansError = useSelector(selectPlansError);
  const refillHistoryStatus = useSelector(selectRefillHistoryStatus);
  const regionalPricesStatus = useSelector(selectRegionalPricesStatus);

  const activePlans = useMemo(() => plans?.filter(value => value.has_subscription === '1'), [ plans ]);
  const inactivePlans = useMemo(() => plans?.filter(value => (value.has_subscription === '2'
    || value.has_subscription === '3'
    || value.has_subscription === '0')), [ plans ]);

  useEffect(() => () => Promise.all([
    dispatch(clearPlans()),
    dispatch(clearRefillHistory()),
    dispatch(clearRegionalPrices()),
    dispatch(clearQaToken()),
  ]), []);

  useEffect(async () => {
    const promises = [];

    if (auth && plansStatus === STATUS.IDLE) {
      promises.push(dispatch(fetchPlans()));
    }
    if (auth && refillHistoryStatus === STATUS.IDLE) {
      promises.push(dispatch(fetchRefillHistory()));
    }

    await Promise.all(promises);

    return null;
  }, [ auth ]);

  useEffect(() => {
    if (plans && auth) {
      if (regionalPricesStatus !== STATUS.PREFETCHED) {
        const regions = plans.reduce((accum, plan) => {
          const region = getPlanRegion(plan);
          return (!accum.includes(region) && region) ? [ ...accum, region ] : [ ...accum ];
        }, []);
        dispatch(fetchRegionalPrices(regions.length ? regions : [ 'US' ]));
      }
    }
  }, [ plans ]);

  useLayoutEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const planToActivateID = searchParams.get('planToActivateID');

    if (plans?.length && planToActivateID) {
      const plan = plans.find(
        ({ external_shopify_id: externalShopifyID }) => externalShopifyID === planToActivateID,
      );

      if (plan?.has_subscription !== '1') {
        dispatch(patchPlan({
          id: null,
          has_subscription: '1',
          external_shopify_id: planToActivateID,
          source: SOURCE.MAH,
        }));
      }
    }
  }, [ plans, location ]);

  return (
    <AuthValidationWrapper auth={auth}>
      <main className='main dashboard'>
        <div className='dashboard-autorefill'>
          <Helmet>
            <title>{props?.route?.meta?.title}</title>
          </Helmet>
          <AutoRefillLogo route={props?.route} />
          <UserMenuMobile route={props?.route} />
          {
            (!plansError && [ STATUS.PREFETCHED, STATUS.SUCCEEDED ].includes(plansStatus)) && (
              (activePlans?.length)
                ? <AutoRefill />
                : <NoActivePlansWrapper activePlans={activePlans} inactivePlans={inactivePlans} qaTokensData={qaTokensData} />
            )
          }
        </div>
        {
          (refillHistoryStatus !== STATUS.LOADING && !!(activePlans?.length || inactivePlans?.length)) && (
            <div className='dashboard-aside'>
              <DashboardHistory refillHistory={refillHistory} />
            </div>
          )
        }
      </main>
    </AuthValidationWrapper>
  );
};

const loadData = store => {
  const actions = [
    fetchPlans(),
    fetchRefillHistory(),
  ];
  return Promise.all(actions.map(action => store.dispatch(action)));
};

export default {
  component: Dashboard,
  loadData,
};
