import {
  configureStore,
  combineReducers,
} from '@reduxjs/toolkit';
import accountReducer from '../client/features/account/accountSlice';
import addressReducer from '../client/features/address/addressSlice';
import authReducer from '../client/features/auth/authSlice';
import billingReducer from '../client/features/billing/billingSlice';
import cancelRefillReducer from '../client/features/cancelRefill/cancelRefillSlice';
import delayRefillReducer from '../client/features/delayRefill/delayRefillSlice';
import expediteRefillReducer from '../client/features/expediteRefill/expediteRefillSlice';
import filterTypesReducer from '../client/features/filterTypes/filterTypesSlice';
import plansReducer from '../client/features/plans/plansSlice';
import patchPlanReducer from '../client/features/plans/patchPlanSlice';
import qaTokenReducer from '../client/features/plans/qaTokenSlice';
import refillReducer from '../client/features/refill/refillSlice';
import regionalPricesReducer from '../client/features/regionalPrices/regionalPricesSlice';
import resetReducer from '../client/features/reset/resetSlice';
import shipmentReducer from '../client/features/shipment/shipmentSlice';
import stripeReducer from '../client/features/stripe/stripeSlice';
import rootReducer, { resetApp } from '../client/features/root/rootSlice';
import paymentMethodReducer from '../client/features/paymentMethod/paymentMethodSlice';

const combinedReducer = combineReducers({
  account: accountReducer,
  address: addressReducer,
  auth: authReducer,
  billing: billingReducer,
  cancelRefill: cancelRefillReducer,
  delayRefill: delayRefillReducer,
  expediteRefill: expediteRefillReducer,
  filterTypes: filterTypesReducer,
  plans: plansReducer,
  patchPlan: patchPlanReducer,
  qaToken: qaTokenReducer,
  refill: refillReducer,
  regionalPrices: regionalPricesReducer,
  reset: resetReducer,
  root: rootReducer,
  shipment: shipmentReducer,
  stripe: stripeReducer,
  paymentMethod: paymentMethodReducer,
});

const getReducer = (state, action) => {
  if (action.type === resetApp.toString()) {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default (preloadedState = {}) => configureStore({
  reducer: getReducer,
  preloadedState,
});
