import React from 'react';
import logo from 'assets/images/logo.svg';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { NAV_ROUTES } from '../../../shared/constants';

const AutoRefillLogo = ({ route }) => (
  <div className='auto-refill__logo'>
    <Link to={NAV_ROUTES[0].pathname} className={route?.path === NAV_ROUTES[0].pathname ? 'disabled' : ''}>
      <img src={logo} alt='Mila' />
    </Link>
  </div>
);

export default AutoRefillLogo;
