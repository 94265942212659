import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axios } from '../../../shared/clients';
import { STATUS } from '../../../shared/enums';
import { addGlobalError } from '../root/rootSlice';
import { setPlans } from '../plans/plansSlice';

/**
 * @type {{data: null, error: null, status: string}}
 */
export const initialState = {
  data: null,
  status: STATUS.IDLE,
  error: null,

  deleteAccountStatus: STATUS.IDLE,
  deleteAccountError: null,

  postAccountStatus: STATUS.IDLE,
  postAccountError: null,

  patchAccountStatus: STATUS.IDLE,
  patchAccountError: null,
};

export const fetchAccount = createAsyncThunk('account/fetchAccount', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.get('/billing');
    return response?.data;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

export const deleteAccount = createAsyncThunk('account/deleteAccount', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.delete('/billing', { data: payload });
    return response?.data;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

export const patchAccount = createAsyncThunk('account/patchAccount', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.patch('/billing', payload);
    return response?.data;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

export const postAccount = createAsyncThunk('account/postAccount', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post('/billing', payload);
    await dispatch(setPlans(response?.data));
    return response?.data;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

/**
 * @type {Slice<{data: null, error: null, status: string}, {}, string>}
 */
export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    clearAccount: state => {
      state.data = initialState.data;
      state.status = initialState.status;
      state.error = initialState.error;

      state.deleteAccountStatus = initialState.deleteAccountStatus;
      state.deleteAccountError = initialState.deleteAccountError;

      state.postAccountStatus = initialState.postAccountStatus;
      state.postAccountError = initialState.postAccountError;

      state.patchAccountStatus = initialState.patchAccountStatus;
      state.patchAccountError = initialState.patchAccountError;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAccount.pending, state => {
        state.status = STATUS.LOADING;
      })
      .addCase(fetchAccount.fulfilled, (state, action) => {
        state.status = STATUS.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(fetchAccount.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload;
      })

      .addCase(deleteAccount.pending, state => {
        state.deleteAccountStatus = STATUS.LOADING;
      })
      .addCase(deleteAccount.fulfilled, (state, action) => {
        state.deleteAccountStatus = STATUS.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(deleteAccount.rejected, (state, action) => {
        state.deleteAccountStatus = STATUS.FAILED;
        state.deleteAccountError = action.payload;
      })

      .addCase(postAccount.pending, state => {
        state.postAccountStatus = STATUS.LOADING;
      })
      .addCase(postAccount.fulfilled, (state, action) => {
        state.postAccountStatus = STATUS.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(postAccount.rejected, (state, action) => {
        state.postAccountStatus = STATUS.FAILED;
        state.postAccountError = action.payload;
      })

      .addCase(patchAccount.pending, state => {
        state.patchAccountStatus = STATUS.LOADING;
      })
      .addCase(patchAccount.fulfilled, (state, action) => {
        state.patchAccountStatus = STATUS.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(patchAccount.rejected, (state, action) => {
        state.patchAccountStatus = STATUS.FAILED;
        state.patchAccountError = action.payload;
      });
  },
});

export const { clearAccount } = accountSlice.actions;

export const selectAccountData = state => state.account.data;
export const selectAccountStatus = state => state.account.status;
export const selectAccountError = state => state.account.error;

export const selectDeleteAccountStatus = state => state.account.deleteAccountStatus;
export const selectDeleteAccountError = state => state.account.deleteAccountError;

export const selectPostAccountStatus = state => state.account.postAccountStatus;
export const selectPostAccountError = state => state.account.postAccountError;

export const selectPatchAccountStatus = state => state.account.patchAccountStatus;
export const selectPatchAccountError = state => state.account.patchAccountError;

export default accountSlice.reducer;
