import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import wavingHand from 'assets/images/waving-hand.png';
import welcomeBlock from 'assets/uploads/welcome-block.png';
import milaLogo from 'assets/uploads/mila-logo-auth.svg';
import { Redirect } from 'react-router';
import { useQueryHook, useWindowDimensions } from '../hooks';
import { LoginForm } from '../components';
import {
  resetError, selectAuthData, selectAuthError, selectAuthStatus, signIn,
} from '../features/auth/authSlice';
import { validateEmail, validatePassword } from '../../shared/utils';
import { STATUS } from '../../shared/enums';
import ResetForm from '../components/ResetForm';
import {
  reset, resetResetError, selectResetError, selectResetStatus,
} from '../features/reset/resetSlice';
import {
  clearGlobalErrors, clearGlobalMessages, removeGlobalError, removeGlobalMessage,
} from '../features/root/rootSlice';

const PLACEHOLDERS_DESKTOP = {
  email: 'Enter your email here',
  password: 'Enter your password here',
};

const PLACEHOLDERS_MOBILE = {
  email: 'Your Email',
  password: 'Your Password',
};
const handleLogoClick = () => {
  window.location.href = MILA_ECOM_HOST;
};

/**
 * @param validation
 * @param preFillEmail
 * @param onChange
 * @param placeholders
 * @param disabled
 * @param onClick
 * @param onClick1
 * @returns {JSX.Element}
 * @constructor
 */
const LoginWelcomeBlock = ({
  validation, preFillEmail, onChange, placeholders, disabled, onClick, onClick1,
}) => ( // TODO: rename onClick1 property
  <>
    <img className='welcome-block__logo' onClick={handleLogoClick} src={milaLogo} alt='logo' />
    <h1 className='welcome-block__title'>
      Welcome, fellow
      air breather!
      {' '}
      <img className='waving-hand' src={wavingHand} alt='waving-hand' />
    </h1>
    <div className='welcome-block__content'>
      <p>
        If you're looking to order more filters and/or manage your existing Auto-Refill, you're in the right spot.
      </p>
    </div>
    <div className='welcome-block__form'>
      <LoginForm
        validation={validation}
        preFillEmail={preFillEmail}
        onChange={onChange}
        placeholders={placeholders}
      />
    </div>
    <div className='welcome-block-bottom'>
      <div className='welcome-block__btn'>
        <button
          type='button'
          className='btn btn--primary'
          disabled={disabled}
          onClick={onClick}
        >
          Login
        </button>
      </div>
      <div className='welcome-block__reset'>
        <span
          className='welcome-block__reset-link'
          onClick={onClick1}
        >
          Forgot my password
        </span>
      </div>
    </div>
  </>
);

/**
 * @param validation
 * @param email
 * @param preFillEmail
 * @param onChange
 * @param placeholders
 * @param disabled
 * @param onClick
 * @param onClick1
 * @returns {JSX.Element}
 * @constructor
 */
const ResetWelcomeBlock = ({
  validation, email, preFillEmail, onChange, placeholders, disabled, onClick, onClick1,
}) => ( // TODO: rename onClick1 property
  <>
    <img className='welcome-block__logo' src={milaLogo} alt='logo' />
    <h1 className='welcome-block__title'>
      Hey, it happens.
    </h1>
    <div className='welcome-block__content'>
      <p>
        Forgot your password? Enter your email and we’ll send you a password reset link.
      </p>
    </div>
    <div className='welcome-block__form'>
      <ResetForm
        validation={validation}
        preFillEmail={email || preFillEmail}
        onChange={onChange}
        placeholders={placeholders}
      />
    </div>
    <div className='welcome-block-bottom'>
      <div className='welcome-block__btn'>
        <button
          type='button'
          className='btn btn--primary'
          disabled={disabled}
          onClick={onClick}
        >
          Reset password
        </button>
      </div>
      <div className='welcome-block__reset'>
        <span
          className='welcome-block__reset-link'
          onClick={onClick1}
        >
          Back to login
        </span>
      </div>
    </div>
  </>
);

/**
 * @param props
 * @returns {*}
 * @constructor
 */
const Login = props => {
  const query = useQueryHook();
  const dispatch = useDispatch();
  const auth = useSelector(selectAuthData);
  const error = useSelector(selectAuthError);
  const status = useSelector(selectAuthStatus);

  const resetErrorStatus = useSelector(selectResetError);
  const resetStatus = useSelector(selectResetStatus);

  const preFillEmail = query.get('pre_fill_email');

  const [ validation, setValidation ] = useState({ email: true, password: true });
  const [ email, setEmail ] = useState(preFillEmail || null);
  const [ password, setPassword ] = useState(null);
  const [ isReset, setIsReset ] = useState(false);
  const [ placeholders, setPlaceholders ] = useState({
    email: '',
    password: '',
  });

  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width > 768) {
      setPlaceholders(PLACEHOLDERS_DESKTOP);
    } else {
      setPlaceholders(PLACEHOLDERS_MOBILE);
    }
  }, [ width ]);

  const disabled = status === STATUS.LOADING
      || !password
      || !email
      || !validation.email;

  const resetDisabled = resetErrorStatus
    || resetStatus === STATUS.LOADING
    || !validation.email;

  const onChange = e => {
    const { id, value } = e.target;

    if (error || resetErrorStatus) {
      dispatch(resetError());
      dispatch(resetResetError());
    }

    if (id === 'user-email') {
      setEmail(value);
      setValidation({ ...validation, email: validateEmail(value) });
    }

    if (id === 'user-password') {
      setPassword(value);
      setValidation({ ...validation, password: validatePassword(value) });
    }
  };

  const signInHandler = e => {
    e.preventDefault();
    dispatch(signIn({ email, password }));
  };

  const resetHandler = e => {
    e.preventDefault();
    dispatch(reset({ email }));
  };

  const keyDownHandler = event => {
    if (event.key === 'Enter') {
      if (isReset && !resetDisabled) {
        resetHandler(event);
      } else if (!isReset && !disabled) {
        signInHandler(event);
      }
    }
  };

  useEffect(() => {
    dispatch(clearGlobalErrors());
    dispatch(clearGlobalMessages());
  }, [ isReset ]);

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [ keyDownHandler ]);

  return auth ? (
    <Redirect to='/dashboard' />
  ) : (
    <main className='main'>
      <Helmet>
        <title>{props?.route?.meta?.title}</title>
      </Helmet>
      <section className='welcome-block'>
        <div className='welcome-block-left'>
          {!isReset && (
            <LoginWelcomeBlock
              validation={validation}
              preFillEmail={preFillEmail}
              onChange={onChange}
              placeholders={placeholders}
              disabled={disabled}
              onClick={e => signInHandler(e)}
              onClick1={() => setIsReset(true)}
            />
          )}
          {!!isReset && (
            <ResetWelcomeBlock
              validation={validation}
              email={email}
              preFillEmail={preFillEmail}
              onChange={onChange}
              placeholders={placeholders}
              disabled={resetDisabled}
              onClick={e => resetHandler(e)}
              onClick1={() => setIsReset(false)}
            />
          )}
        </div>
        <div className='welcome-block-right'>
          <div className='welcome-block-right-bg' />
          <img className='welcome-block__img' src={welcomeBlock} alt='' />
        </div>
      </section>
    </main>
  );
};

export default {
  component: Login,
};
