import { createSlice } from '@reduxjs/toolkit';

/**
 * @type {{patchQaToken: {error: null, status: string}, fetchQaTokens: {data: null, error: null, status: string}}}
 */
export const initialState = {
  data: null,

};

/**
 * @type {Slice<{data: null, error: null, status: string}, {clearShipments: reducers.clearShipments}, string>}
 */
export const qaTokenSlice = createSlice({
  name: 'qaToken',
  initialState,
  reducers: {
    clearQaToken: state => {
      state.data = initialState.data;
    },
    setQaToken: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { clearQaToken, setQaToken } = qaTokenSlice.actions;

export const selectQaTokensData = state => state.qaToken.data;

export default qaTokenSlice.reducer;
