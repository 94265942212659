import React, { useEffect } from 'react';
import loadable from '@loadable/component';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { AuthValidationWrapper } from '../components';
import { selectAuthData } from '../features/auth/authSlice';
import {
  fetchRefillHistory, selectRefillHistoryStatus,
} from '../features/refill/refillSlice';
import { STATUS } from '../../shared/enums';

const ReferralPageContent = loadable(() => import(/* webpackPrefetch: true */ '../components/Referral/ReferralPageContent'));

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Referral = props => {
  const auth = useSelector(selectAuthData);
  const dispatch = useDispatch();
  const refillHistoryStatus = useSelector(selectRefillHistoryStatus);

  useEffect(async () => {
    const promises = [];

    if (auth && refillHistoryStatus === STATUS.IDLE) {
      promises.push(dispatch(fetchRefillHistory()));
    }

    await Promise.all(promises);

    return null;
  }, [ auth ]);

  return (
    <AuthValidationWrapper auth={auth}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <ReferralPageContent {...props} />
    </AuthValidationWrapper>
  );
};

const loadData = store => {
  const actions = [
    fetchRefillHistory(),
  ];
  return Promise.all(actions.map(action => store.dispatch(action)));
};

export default {
  component: Referral,
  loadData,
};
