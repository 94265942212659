import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { HelmetProvider } from 'react-helmet-async';
import { loadableReady } from '@loadable/component';
import { Elements } from '@stripe/react-stripe-js';

import Routes from '../shared/routes';
import createStore from '../shared/createStore';
import stripe from '../shared/clients/stripe';

const state = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;

const store = createStore(state);

loadableReady(() => {
  ReactDOM.hydrate(
    <Provider store={store}>
      <Elements stripe={stripe}>
        <HelmetProvider>
          <CookiesProvider>
            <BrowserRouter>
              {renderRoutes(Routes)}
            </BrowserRouter>
          </CookiesProvider>
        </HelmetProvider>
      </Elements>
    </Provider>,
    document.getElementById('root'),
  );
});
