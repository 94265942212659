/**
 * Сonvert to boolean type and return test result
 *
 * @param value
 * @return {*}
 */
const getTrue = value => {
  const processed = typeof value === 'string' ? value.toLowerCase().trim() : value;
  return [ true, 'true', 1 ].some(t => t === processed);
};

export default getTrue;
