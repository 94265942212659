import React, { useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 *
 * @param ref
 * @param navActive
 * @param cb
 */
const useOutsideAlerter = (ref, navActive, cb) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     *
     * @param event
     */
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target) && navActive) cb ? cb() : null;
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [ ref, navActive ]);
};

export default useOutsideAlerter;
