import React, { useEffect, useState } from 'react';

import inProgressBadge from 'assets/uploads/in-progress-badge.svg';
import shippedBadge from 'assets/uploads/shipped-badge.svg';
import shippedCancel from 'assets/uploads/shipment-cancel.svg';
import shippedCancelNew from 'assets/uploads/shipment-cancel-new.svg';

const HistoryItemStatus = ({ fulfillment, service }) => {
  const [ fulfillmentBgColor, setFulfillmentBgColor ] = useState('');

  useEffect(() => {
    let output = '';

    if (fulfillment === 1) output = 'rgba(28, 228, 213, 0.1)';
    if (fulfillment === 0) output = 'rgba(255, 193, 64, 0.1)';
    if (fulfillment === -1) output = 'rgba(207, 207, 207, 0.2)';
    if (fulfillment === null) output = 'rgba(207, 207, 207, 0.2)';

    setFulfillmentBgColor(output);
  }, [ fulfillment ]);

  return (
    <div className='history-item-header-status'>
      {fulfillment === 0 && (
        <div className='history-item-header-status-item' style={{ backgroundColor: fulfillmentBgColor }}>
          <div className='history-item-header-status-item__icon'>
            <img src={inProgressBadge} alt='' />
          </div>
          <div className='history-item-header-status-item__text'>
            Your order is being processed.
          </div>
        </div>
      )}
      {fulfillment === 1 && (
        <div className='history-item-header-status-item' style={{ backgroundColor: fulfillmentBgColor }}>
          <div className='history-item-header-status-item__icon'>
            <img src={shippedBadge} alt='' />
          </div>
          <div className='history-item-header-status-item__text'>
            Order delivered.
          </div>
        </div>
      )}
      {(fulfillment === -1 && service !== null && service === 'rsl') && (
        <div className='history-item-header-status-item' style={{ backgroundColor: fulfillmentBgColor }}>
          <div className='history-item-header-status-item__icon'>
            <img src={inProgressBadge} alt='' />
          </div>
          <div className='history-item-header-status-item__text'>
            Your order is currently on backorder and will ship out as soon as possible.
          </div>
        </div>
      )}
      {(fulfillment === -1 && service !== null && service !== 'rsl') && (
        <div className='history-item-header-status-item' style={{ backgroundColor: fulfillmentBgColor }}>
          <div className='history-item-header-status-item__icon'>
            <img src={shippedCancel} alt='' />
          </div>
          <div className='history-item-header-status-item__text'>
            Your order has been cancelled. Please reach out to
            {' '}
            <a href='mailto:support@milacares.com'>support@milacares.com</a>
            {' '}
            with any questions.
          </div>
        </div>
      )}
      {(fulfillment === -1 || fulfillment === null) && (
        <div className='history-item-header-status-item' style={{ backgroundColor: fulfillmentBgColor }}>
          <div className='history-item-header-status-item__icon'>
            <img src={shippedCancelNew} alt='' />
          </div>
          <div className='history-item-header-status-item__text'>
            No tracking information is available.
          </div>
        </div>
      )}
    </div>
  );
};

export default HistoryItemStatus;
