import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from 'assets/images/logo.svg';
import { useSelector } from 'react-redux';
import { selectAuthData } from '../features/auth/authSlice';

/**
 * @param items
 * @returns {JSX.Element}
 * @constructor
 */
const HeaderDemo = ({ items }) => {
  const auth = useSelector(selectAuthData);
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const indexLink = auth ? '/dashboard' : '/';

  return (
    <header className='header header-demo'>
      {
        auth
        && (
          <div className='header-nav-demo-items'>
            {
              items.map(headerItem => (headerItem.pathname === '/referral' ? (
                <a href={headerItem.pathname}>
                  <div className={`header-nav-demo-item ${headerItem.pathname === pathname ? 'is-active' : ''}`}>
                    <div className='header-nav-demo-item__icon'>
                      <svg className={`icon ${headerItem.iconClass}`}>
                        <use xlinkHref={`#${headerItem.iconClass}`} />
                      </svg>
                    </div>
                    <p className='header-nav-demo-item__label'>
                      {headerItem.text}
                    </p>
                  </div>
                </a>
              ) : (
                <Link to={headerItem.pathname}>
                  <div className={`header-nav-demo-item ${headerItem.pathname === pathname ? 'is-active' : ''}`}>
                    <div className='header-nav-demo-item__icon'>
                      <svg className={`icon ${headerItem.iconClass}`}>
                        <use xlinkHref={`#${headerItem.iconClass}`} />
                      </svg>
                    </div>
                    <p className='header-nav-demo-item__label'>
                      {headerItem.text}
                    </p>
                  </div>
                </Link>
              )))
            }
          </div>
        )
      }
    </header>
  );
};

export default HeaderDemo;
