import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeGlobalError, selectErrors } from '../features/root/rootSlice';
import ErrorPopupItem from './ErrorPopupItem';

const ErrorPopup = () => {
  const [ show, setShow ] = useState(false);
  const dispatch = useDispatch();

  const errors = useSelector(selectErrors);
  const [ errorsViewMap, setErrorsViewMap ] = useState([]);

  const removeError = idx => {
    dispatch(removeGlobalError(idx));
  };

  useEffect(() => {
    const output = [];
    errors.forEach(value => {
      output.push({
        ...value,
        show: true,
      });
    });
    setErrorsViewMap(output);
  }, [ errors ]);

  useEffect(() => {
    setShow(errorsViewMap.some(error => error.show));
  }, [ errorsViewMap ]);

  return (show && (
    <div className='error-popup-bg'>
      {errorsViewMap.length && errorsViewMap.map((error, idx) => <ErrorPopupItem error={error} idx={idx} removeError={removeError} />)}
    </div>
  )
  );
};

export default ErrorPopup;
