import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useWindowDimensions } from '../hooks';

/**
 * @param onClick
 * @returns {JSX.Element}
 * @constructor
 */
const ModalCloseBg = ({ onClick }) => <div className='modal-close-bg' onClick={onClick} />;

/**
 * @param children
 * @param height
 * @param onClick
 * @param onTouchEnd
 * @param onTouchMove
 * @param onTouchStart
 * @param modalMainRef
 * @param resizing
 * @param width
 * @returns {JSX.Element}
 * @constructor
 */
const ModalMain = ({
  children, height, onClick, onTouchEnd, onTouchMove, onTouchStart, modalMainRef, resizing, width,
}) => (
  <div
    className={`modal-main ${resizing ? 'resizing' : ''}`}
    style={{ width: `${width ?? 730}px`, height }}
    ref={modalMainRef}
    onClick={onClick}
    onTouchStart={onClick}
    onTouchMove={onClick}
    onTouchEnd={onClick}
  >
    <div
      className='modal-swipe-close'
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      onClick={onClick}
    >
      <div className='modal-swipe-close-line' />
    </div>
    <div onClick={onClick}>
      {children}
    </div>
  </div>
);

const ModalBase = props => {
  const {
    children,
    width,
    show,
    setShow,
    className,
  } = props;

  const [ startHeight, setStartHeight ] = useState(0);
  const [ startY, setStartY ] = useState(null);
  const [ currentY, setCurrentY ] = useState(null);
  const [ isSwipingDown, setIsSwipingDown ] = useState(false);
  const [ isResizing, setIsResizing ] = useState(false);
  const [ height, setHeight ] = useState('auto');
  const dimentions = useWindowDimensions();

  const modalMainRef = useCallback(node => {
    if (node !== null && !startHeight) {
      setStartHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const handleTouchStart = e => {
    if (dimentions.width <= 768) {
      setStartY(e.touches[0].clientY);
      setCurrentY(e.touches[0].clientY);
      setIsResizing(true);
    }
    e.stopPropagation();
  };

  const handleTouchMove = e => {
    if (dimentions.width <= 768) {
      setCurrentY(e.touches[0].clientY);
      if (currentY - startY > 0) {
        setIsSwipingDown(true);
      } else {
        setIsSwipingDown(false);
      }
      setHeight(`${startHeight - (currentY - startY)}px`);
    }
    e.stopPropagation();
  };

  const handleTouchEnd = e => {
    if (dimentions.width <= 768) {
      if (isSwipingDown) {
        setIsSwipingDown(false);
        setIsResizing(false);
        setHeight('auto');
        setShow(false);
      } else {
        setIsResizing(false);
        setHeight('auto');
      }
    }
    e.stopPropagation();
  };

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [ show ]);

  return (
    show && (
      <div
        className={`modal-background ${className ?? ''}`}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <ModalCloseBg onClick={() => setShow(false)} />
        <ModalMain
          resizing={isResizing}
          width={width}
          height={height}
          modalMainRef={modalMainRef}
          onClick={e => e.stopPropagation()}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {children}
        </ModalMain>
      </div>
    )
  );
};

export default ModalBase;
