import { PRODUCT_VARIANTS_ABBREVIATION, PRODUCT_VARIANT_NAMES } from '../enums';
import getKeyByValue from './getKeyByValue';
import getPriceByRegion from './getPriceByRegion';

/**
 * Get price from variant
 *
 * @param {*} regionalPrices
 * @param {*} activePlan
 * @param {*} variantAbbreviation
 * @return {*}
 */
const getPriceFromVariant = (regionalPrices, activePlan, variantAbbreviation) => {
  const filterName = getKeyByValue(PRODUCT_VARIANTS_ABBREVIATION, variantAbbreviation);
  const filterType = getKeyByValue(PRODUCT_VARIANT_NAMES, filterName);
  const regionalPricesByType = regionalPrices?.filter(value => value.variant === +filterType);
  const regionalPriceByRegion = getPriceByRegion(regionalPricesByType, activePlan);
  return regionalPriceByRegion?.price;
};

export default getPriceFromVariant;
