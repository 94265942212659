import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthStatus } from '../features/auth/authSlice';
import {
  selectAccountStatus, selectDeleteAccountStatus, selectPatchAccountStatus, selectPostAccountStatus,
} from '../features/account/accountSlice';
import { selectCancelRefillStatus } from '../features/cancelRefill/cancelRefillSlice';
import { selectPatchPlanStatus } from '../features/plans/patchPlanSlice';
import { selectDelayRefillStatus } from '../features/delayRefill/delayRefillSlice';
import { selectExpediteRefillStatus } from '../features/expediteRefill/expediteRefillSlice';
import { selectFilterTypesStatus } from '../features/filterTypes/filterTypesSlice';
import { selectRegionalPricesStatus } from '../features/regionalPrices/regionalPricesSlice';
import { selectPlansStatus } from '../features/plans/plansSlice';
import { selectRefillHistoryStatus } from '../features/refill/refillSlice';
import {
  selectSendOrderConfirmationStatus, selectSendRefillConfirmationStatus, selectShipmentStatus,
} from '../features/shipment/shipmentSlice';
import { selectAddressStatus } from '../features/address/addressSlice';
import { selectStripeStatus } from '../features/stripe/stripeSlice';
import { selectBillingStatus } from '../features/billing/billingSlice';
import { STATUS } from '../../shared/enums';
import { selectPaymentMethodStatus } from '../features/paymentMethod/paymentMethodSlice';

const Loader = () => {
  const [ show, setShow ] = useState(true);

  const authStatus = useSelector(selectAuthStatus);
  const plansStatus = useSelector(selectPlansStatus);
  const accountStatus = useSelector(selectAccountStatus);
  const cancelRefillStatus = useSelector(selectCancelRefillStatus);
  const patchPlanStatus = useSelector(selectPatchPlanStatus);
  const delayRefillStatus = useSelector(selectDelayRefillStatus);
  const deleteAccountStatus = useSelector(selectDeleteAccountStatus);
  const expediteRefillStatus = useSelector(selectExpediteRefillStatus);
  const filterTypesStatus = useSelector(selectFilterTypesStatus);
  const patchAccountStatus = useSelector(selectPatchAccountStatus);
  const postAccountStatus = useSelector(selectPostAccountStatus);
  const regionalPricesStatus = useSelector(selectRegionalPricesStatus);
  const refillHistoryStatus = useSelector(selectRefillHistoryStatus);
  const shipmentStatus = useSelector(selectShipmentStatus);
  const sendOrderConfirmationStatus = useSelector(selectSendOrderConfirmationStatus);
  const sendRefillConfirmationStatus = useSelector(selectSendRefillConfirmationStatus);
  const patchAddressStatus = useSelector(selectAddressStatus);
  const stripeStatus = useSelector(selectStripeStatus);
  const billingStatus = useSelector(selectBillingStatus);
  const paymentMethodStatus = useSelector(selectPaymentMethodStatus);

  useEffect(
    () => {
      setShow((authStatus === STATUS.LOADING
        || plansStatus === STATUS.LOADING
        || accountStatus === STATUS.LOADING
        || cancelRefillStatus === STATUS.LOADING
        || patchPlanStatus === STATUS.LOADING
        || delayRefillStatus === STATUS.LOADING
        || deleteAccountStatus === STATUS.LOADING
        || expediteRefillStatus === STATUS.LOADING
        || filterTypesStatus === STATUS.LOADING
        || patchAccountStatus === STATUS.LOADING
        || shipmentStatus === STATUS.LOADING
        || sendOrderConfirmationStatus === STATUS.LOADING
        || sendRefillConfirmationStatus === STATUS.LOADING
        || postAccountStatus === STATUS.LOADING
        || refillHistoryStatus === STATUS.LOADING
        || patchAddressStatus === STATUS.LOADING
        || regionalPricesStatus === STATUS.LOADING
        || stripeStatus === STATUS.LOADING
        || billingStatus === STATUS.LOADING
        || paymentMethodStatus === STATUS.LOADING));
    },
    [
      authStatus,
      plansStatus,
      accountStatus,
      cancelRefillStatus,
      patchPlanStatus,
      delayRefillStatus,
      deleteAccountStatus,
      expediteRefillStatus,
      filterTypesStatus,
      patchAccountStatus,
      postAccountStatus,
      sendOrderConfirmationStatus,
      sendRefillConfirmationStatus,
      shipmentStatus,
      regionalPricesStatus,
      refillHistoryStatus,
      patchAddressStatus,
      stripeStatus,
      billingStatus,
    ],
  );

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [ show ]);

  return (
    show && (
      <div className='loader-bg'>
        <div className='loader'>
          <div className='loader-spinner'>
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>
    )
  );
};

export default Loader;
