import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SidebarLogo from './SidebarLogo';
import SidebarLogoutForm from './SidebarLogoutForm';
import SidebarMenuItem from './SidebarMenuItem';
import { selectAuthData, signOut } from '../features/auth/authSlice';
import { NAV_ROUTES } from '../../shared/constants';

/**
 * @param items
 * @returns {JSX.Element}
 * @constructor
 */
const Sidebar = ({ items }) => {
  const dispatch = useDispatch();
  const auth = useSelector(selectAuthData);

  const name = auth ? `${auth.firstName ?? ''} ${auth.lastName ?? ''}` : '';
  const indexLink = auth ? NAV_ROUTES[0].pathname : '/';

  const onSignOutClick = e => {
    e.preventDefault();
    const { refresh_token: token } = auth;
    dispatch(signOut({ token }));
  };

  return (
    <div className='sidebar'>
      <SidebarLogo to={indexLink} />
      <ul className='sidebar-menu'>
        {items.map(item => (
          <SidebarMenuItem item={item} />
        ))}
      </ul>
      <SidebarLogoutForm name={name} onClick={onSignOutClick} />
    </div>
  );
};

export default Sidebar;
