import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS } from '../../../shared/enums';
import { addGlobalError } from '../root/rootSlice';
import { postBilling } from '../billing/billingSlice';

/**
 * @type {{data: null, error: null, status: string}}
 */
export const initialState = {
  data: null,
  status: STATUS.IDLE,
  error: null,
};

/**
 * @type {AsyncThunk<unknown, void, AsyncThunkConfig>}
 */
export const postStripe = createAsyncThunk('account/postStripe', async ({ payload, stripe, card }, { rejectWithValue, dispatch }) => {
  try {
    const options = { type: 'card', card };
    const { paymentMethod, error } = await stripe.createPaymentMethod(options);

    if (paymentMethod) {
      const {
        card: {
          checks, networks, three_d_secure_usage, ...rest
        }, id,
      } = paymentMethod;

      await dispatch(postBilling({ ...payload, card: { ...rest, id }}));
    }

    if (error) {
      dispatch(addGlobalError(error));
    }

    return error ? rejectWithValue(error) : paymentMethod;
  } catch (e) {
    dispatch(addGlobalError(e));
    return rejectWithValue(e);
  }
});

/**
 * @type {Slice<{data: null, error: null, status: string}, {}, string>}
 */
export const stripeSlice = createSlice({
  name: 'stripe',
  initialState,
  reducers: {
    clearStripe: state => {
      state.data = initialState.data;
      state.status = initialState.status;
      state.error = initialState.error;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(postStripe.pending, state => {
        state.status = STATUS.LOADING;
      })
      .addCase(postStripe.fulfilled, (state, action) => {
        state.status = STATUS.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(postStripe.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload;
      });
  },
});

export const { clearStripe } = stripeSlice.actions;

export const selectStripeData = state => state.stripe.data;
export const selectStripeStatus = state => state.stripe.status;
export const selectStripeError = state => state.stripe.error;

export default stripeSlice.reducer;
