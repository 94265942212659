/**
 * Get random int from intervar
 *
 * @param min
 * @param max
 * @return {int}
 */
const randomIntFromInterval = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

export default randomIntFromInterval;
