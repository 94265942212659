import React from 'react';
import { Helmet } from 'react-helmet-async';

import faviconIco from 'assets/favicon/favicon.ico';
import faviconSvg from 'assets/favicon/favicon.svg';
import appleTouchIcon from 'assets/favicon/apple-touch-icon.png';
import favicon32x32 from 'assets/favicon/favicon-32x32.png';
import favicon192x192 from 'assets/favicon/favicon-192x192.png';
import milaIcon from 'assets/favicon/mila-icon.png';

const CommonHelmetWrapper = () => (
  <Helmet>
    {/* <!-- favicon's  --> */}
    <link rel='icon' href={faviconIco} sizes='any' />
    <link rel='icon' href={faviconSvg} type='image/svg+xml' />
    <link rel='apple-touch-icon' href={appleTouchIcon} />
    <link rel='icon' href={favicon32x32} sizes='32x32' />
    <link rel='icon' href={favicon192x192} sizes='192x192' />
    <link rel='apple-touch-icon' href={milaIcon} />
    <meta name='msapplication-TileImage' content={milaIcon} />

    <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
    <meta name='viewport' content='width=device-width, initial-scale=1' />

    {/* <!-- Primary Meta Tags --> */}
    <meta
      name='title'
      content='Say hello 👋 to Mila, the refreshingly thoughtful air purifier'
    />
    <meta
      name='description'
      content='Air breathers, rejoice. Meet Mila, the first air purifier designed with real, air-breathing humans in mind.'
    />

    {/* <!-- Open Graph / Facebook --> */}
    <meta
      property='og:title'
      content='Say hello 👋 to Mila, the refreshingly thoughtful air purifier'
    />
    <meta
      property='og:description'
      content='Air breathers, rejoice. Meet Mila, the first air purifier designed with real, air-breathing humans in mind.'
    />
    <meta property='og:url' content='https://www.milacares.com/' />
    <meta property='og:type' content='articles' />
    <meta property='og:site_name' content='Mila' />
    <meta
      name='image'
      property='og:image'
      content='https://www.milacares.com/assets/images/milacares.jpg'
    />
    <meta name='author' content='The Mila Team' />

    {/* <!-- Twitter --> */}
    <meta property='twitter:card' content='summary_large_image' />
    <meta property='twitter:url' content='https://www.milacares.com/' />
    <meta
      property='twitter:title'
      content='Say hello 👋 to Mila, the refreshingly thoughtful air purifier'
    />
    <meta
      property='twitter:description'
      content='Air breathers, rejoice. Meet Mila, the first air purifier designed with real, air-breathing humans in mind.'
    />
    <meta
      property='twitter:image'
      content='https://milacares.com/assets/images/milacares.jpg'
    />
  </Helmet>
);

export default CommonHelmetWrapper;
