import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axios } from '../../../shared/clients';
import { STATUS } from '../../../shared/enums';
import { addGlobalError, addGlobalMessage } from '../root/rootSlice';

/**
 * @type {{data: null, error: null, status: string}}
 */
export const initialState = {
  data: null,
  status: STATUS.IDLE,
  error: null,
};

export const reset = createAsyncThunk('reset/reset', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const { email } = payload;
    const response = await axios.post('/user/reset', {
      email: email.toLowerCase(),
    });
    if (response?.status === 200) {
      dispatch(addGlobalMessage({ message: 'Please check your email for password reset instructions.' }));
    }
    return response?.data;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

/**
 * @type {Slice<{data: null, error: null, status: string}, {}, string>}
 */
export const resetSlice = createSlice({
  name: 'reset',
  initialState,
  reducers: {
    resetResetError: state => {
      state.error = initialState.error;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(reset.pending, state => {
        state.status = STATUS.LOADING;
      })
      .addCase(reset.fulfilled, (state, action) => {
        state.status = STATUS.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(reset.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload;
      });
  },
});

export const { resetResetError } = resetSlice.actions;

export const selectResetData = state => state.reset.data;
export const selectResetStatus = state => state.reset.status;
export const selectResetError = state => state.reset.error;

export default resetSlice.reducer;
