import React, { useRef } from 'react';
import { useOutsideAlerter } from '../hooks';

/**
 * Component that alerts if you click outside of it
 *
 * @param children
 * @param navActive
 * @param cb
 * @returns {JSX.Element}
 * @constructor
 */
const OutsideAlerter = ({ children, navActive, cb }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, navActive, cb);

  return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideAlerter;
