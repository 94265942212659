import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axios } from '../../../shared/clients';
import { STATUS } from '../../../shared/enums';
import { addGlobalError } from '../root/rootSlice';

/**
 * @type {{data: null, error: null, status: string}}
 */
export const initialState = {
  data: null,
  status: STATUS.IDLE,
  error: null,
};

/**
 * Creates an asynchronous thunk for sending a payment method update email.
 *
 * @param {Object} payload - The payload containing the required data for sending the email.
 * @param {Object} options - The options object for handling dispatch and error handling.
 * @returns {Promise} - A promise that resolves to the response data or rejects with an error.
 *
 * @throws {Error} - If an error occurs during the process.
 */
export const paymentMethodSendUpdateEmail = createAsyncThunk('paymentMethod/paymentMethodSendUpdateEmail', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post('/payment-method-send-update-email', payload);
    return response?.data;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

/**
 * @type {Slice<{data: null, error: null, status: string}, {clearPaymentMethod: reducers.clearPaymentMethod}, string>}
 */
export const paymentMethodSlice = createSlice({
  name: 'paymentMethod',
  initialState,
  reducers: {
    clearPaymentMethod: state => {
      state.data = initialState.data;
      state.status = initialState.status;
      state.error = initialState.error;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(paymentMethodSendUpdateEmail.pending, state => {
        state.status = STATUS.LOADING;
      })
      .addCase(paymentMethodSendUpdateEmail.fulfilled, (state, action) => {
        state.status = STATUS.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(paymentMethodSendUpdateEmail.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload;
      });
  },
});

export const { clearPaymentMethod } = paymentMethodSlice.actions;

export const selectPaymentMethodData = state => state.paymentMethod.data;
export const selectPaymentMethodStatus = state => state.paymentMethod.status;
export const selectPaymentMethodError = state => state.paymentMethod.error;

export default paymentMethodSlice.reducer;
