import getPlanRegion from './getPlanRegion';

/**
 * Get price by region and auto-refill plan
 *
 * @param {*} regionalPricesByType
 * @param {*} plan
 * @return {*}
 */
const getPriceByRegion = (regionalPricesByType, plan) => {
  let output = null;

  const region = getPlanRegion(plan);

  if (region) {
    [ output ] = regionalPricesByType.filter(value => value.region === region);
  }

  return output;
};

export default getPriceByRegion;
