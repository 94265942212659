import React, { useEffect, useState } from 'react';
import Arrow from 'assets/uploads/arrow-menu.svg';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthData, signOut } from '../features/auth/authSlice';
import OutsideAlerter from './OutsideAlerter';
import RandomIcon from './Dashboard/RandomIcon';

const UserMenuMobile = () => {
  const dispatch = useDispatch();
  const auth = useSelector(selectAuthData);
  const [ name, setName ] = useState('');
  const [ isOpen, setIsOpen ] = useState(false);

  useEffect(() => {
    if (auth) {
      setName(`${auth.firstName ?? ''} ${auth.lastName ? `${auth.lastName[0].toUpperCase()}.` : ''}`);
    }
  }, [ auth ]);

  const onSignOutClick = e => {
    e.preventDefault();
    const { refresh_token: token } = auth;
    dispatch(signOut({ token }));
  };

  const closeDropdownHandle = () => {
    setIsOpen(false);
  };

  return (
    <div
      className='user-menu'
    >
      <RandomIcon />
      <OutsideAlerter navActive={isOpen} cb={() => closeDropdownHandle()}>
        <div className='user-menu-right'>
          <div className='user-menu__name' onClick={() => setIsOpen(!isOpen)}>
            {name}
            <img src={Arrow} alt='arrow' className={`${isOpen ? 'rotated' : ''}`} />
          </div>
          {isOpen && (
            <div className='user-menu-items'>
              <button type='button' className='user-menu-items-item logout' onClick={onSignOutClick}>
                Logout
              </button>
            </div>
          )}
        </div>
      </OutsideAlerter>
    </div>
  );
};

export default UserMenuMobile;
