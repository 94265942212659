import React from 'react';
import { Link, useLocation } from 'react-router-dom';

/**
 * @param item
 * @returns {JSX.Element}
 * @constructor
 */
const SidebarMenuItem = ({ item }) => {
  const location = useLocation();
  return (
    <li className={`sidebar-menu-item ${item.pathname === location.pathname ? 'selected' : ''}`}>
      {item.pathname === '/referral' ? (
        <a
          href={item.pathname}
          className='sidebar-menu-item-link'
        >
          <div className='icon-container'>
            <svg className={`icon ${item.iconClass}`}>
              <use xlinkHref={`#${item.iconClass}`} />
            </svg>
          </div>
          <span>{item.text || ''}</span>
        </a>
      ) : (
        <Link
          to={item.pathname}
          className='sidebar-menu-item-link'
        >
          <div className='icon-container'>
            <svg className={`icon ${item.iconClass}`}>
              <use xlinkHref={`#${item.iconClass}`} />
            </svg>
          </div>
          <span>{item.text || ''}</span>
        </Link>
      )}
    </li>
  );
};

export default SidebarMenuItem;
