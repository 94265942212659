import React from 'react';
import { Helmet } from 'react-helmet-async';
import { AuthValidationWrapper } from '../components';

const NotFound = ({ staticContext = {}, auth }) => {
  staticContext.notFound = true;

  return (
    <AuthValidationWrapper auth={auth}>
      <div className='welcome-block'>
        <Helmet>
          <title>Page Not Found</title>
        </Helmet>
        <h1>Page Not Found.</h1>
        <p>Please try again.</p>
      </div>
    </AuthValidationWrapper>
  );
};

NotFound.defaultProps = {
  staticContext: {},
};

export default {
  component: NotFound,
};
