import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axios } from '../../../shared/clients';
import { STATUS } from '../../../shared/enums';
import { addGlobalError, resetApp } from '../root/rootSlice';

/**
 * @type {{data: null, error: null, status: string}}
 */
export const initialState = {
  data: null,
  status: STATUS.IDLE,
  error: null,
};

export const signIn = createAsyncThunk('auth/signIn', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const { email, password } = payload;
    const response = await axios.post('/login', {
      email: email?.toLowerCase() || '',
      password,
    });
    return response?.data;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

export const signOut = createAsyncThunk('auth/signOut', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post('/logout', payload);
    dispatch(resetApp());
    return response?.data;
  } catch (e) {
    dispatch(addGlobalError({ ...e.response?.data, status: e.response?.status }));
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

export const tokenExchange = createAsyncThunk('auth/tokenExchange', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post('/token-exchange', payload);
    return response?.data;
  } catch (e) {
    dispatch(resetApp());
    return rejectWithValue({ ...e.response?.data, status: e.response?.status });
  }
});

/**
 * @type {Slice<{data: null, error: null, status: string}, {}, string>}
 */
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetError: state => {
      state.error = initialState.error;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(signIn.pending, state => {
        state.status = STATUS.LOADING;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.status = STATUS.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload;
      })
      .addCase(signOut.pending, state => {
        state.status = STATUS.LOADING;
      })
      .addCase(signOut.fulfilled, state => {
        state.status = STATUS.SUCCEEDED;
        state.data = null;
      })
      .addCase(signOut.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload;
      })
      .addCase(tokenExchange.pending, state => {
        state.status = STATUS.LOADING;
      })
      .addCase(tokenExchange.fulfilled, (state, action) => {
        state.status = STATUS.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(tokenExchange.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.payload;
      });
  },
});

export const { resetError } = authSlice.actions;

export const selectAuthData = state => state.auth.data;
export const selectAuthStatus = state => state.auth.status;
export const selectAuthError = state => state.auth.error;

export default authSlice.reducer;
